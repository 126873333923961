import { styled } from "styled-components";
import { COLORS, FONT_WEIGHT } from "../../theme";

export const Section = styled.div`
  width: 100%;
  background-color: ${COLORS.GRAY};
  padding: 48px 0;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1200px) {
    flex-direction: column-reverse;
  }
`;

export const LogoImg = styled.img`
  width: 280px;
  cursor: pointer;
  margin-right: 32px;

  @media (max-width: 1400px) {
    width: 200px;
  }

  @media (max-width: 1200px) {
    margin-top: 18px;
    margin-right: 0;
    width: 180px;
  }
`;

export const Infos = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 850px) {
    flex-direction: column;
    text-align: center;
  }
`;

export const Info = styled.p`
  font-size: 22px;
  

  @media (max-width: 1400px) {
    font-size: 18px;
  }

  @media (max-width: 850px) {
    margin-bottom: 32px;
    line-height: 32px;

    &.contact {
      line-height: 48px;
      padding: 14px 0;
    }
  }

  b {
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
  }

  a {
    color: #000;
    text-decoration: none;
    display: flex;
    align-items: center;

    img {
      margin-top: 4px;
    }

    span {
      margin-left: 8px;
    }

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const LogoMondiale = styled.img`
  width: 240px;

  @media (max-width: 1400px) {
    width: 200px;
  }
`;

export const Target = styled.p`
  margin-top: 32px;
  font-weight: ${FONT_WEIGHT.SEMI_BOLD};
  text-align: center;
`;
