import { SlideType } from "../../../mocks/packages";
import { PackagesProps } from "../../../types/packages";
import { packageLink } from "../../../utils/packageLink";
import {
  Bloqueio,
  Info,
  InfoTitle,
  SlideContainer,
  SlideImage,
  SlideInfo,
} from "./style";
import Aviao from "../../../assets/images/airplane.svg";

type SlidePackegesProps = {
  pack: PackagesProps;
  props: any;
};

export default function Slide({ props, pack }: SlidePackegesProps) {
  const { index, ...otherProps } = props;

  return (
    <SlideContainer {...otherProps} to={`pacote/${packageLink(pack.title)}`}>
      {pack.isBloqueio && (
        <Bloqueio>
          <img src={Aviao} alt="avião" width={28} />
          <p>
            COM <span>BLOQUEIO AÉREO</span>
          </p>
        </Bloqueio>
      )}
      <SlideImage backgroundImage={pack.image} />
      <SlideInfo>
        <InfoTitle>{pack.title}</InfoTitle>
        <Info>
          {pack.period}
          <br />
          Saída: {pack.initialDate}
        </Info>
        <Info>
          A partir de <b>{pack.qtdParcel}x</b> de
          <br />
          <b>
            {pack.type === SlideType.internacional ? "USD " : "R$ "}
            <span>
              {Intl.NumberFormat("pt-br", {
                minimumFractionDigits: 2,
              }).format(pack.parcelValue)}
            </span>
          </b>
        </Info>
        <Info className="totalValue">
          Total à vista por:{" "}
          <b>
            {pack.type === SlideType.internacional ? "USD " : "R$ "}
            {Intl.NumberFormat("pt-br", {
              minimumFractionDigits: 2,
            }).format(pack.value)}
          </b>
        </Info>
      </SlideInfo>
    </SlideContainer>
  );
}
