import { styled } from "styled-components";
import { COLORS, FONT_WEIGHT } from "../../../theme";
import DefaultImage from "../../../assets/images/packages/default.png";
import ArrowRight from "../../../assets/images/arrow-right.svg";
import ArrowLeft from "../../../assets/images/arrow-left.svg";
import { Link } from "react-router-dom";

type BackgroundImageProps = {
  backgroundImage?: string;
};

export const Section = styled.div`
  width: 100%;
  padding-top: 135px;

  @media (max-width: 1400px) {
    padding-top: 114px;
  }

  @media (max-width: 950px) {
    padding-top: 98px;
  }

  div.slick-slider {
    button.slick-arrow {
      background-color: ${COLORS.PRIMARY};
      background-image: url(${ArrowRight});
      background-size: 22px;
      background-repeat: no-repeat;
      background-position: center;
      width: 40px;
      height: 40px;
      border-radius: 10px;
      right: -40px;

      @media (max-width: 1280px) {
        right: -24px;
      }

      &::before {
        opacity: 1;
        content: "";
      }
    }

    button.slick-arrow.slick-prev {
      background-image: url(${ArrowLeft});
      z-index: 9;
      left: -40px;

      @media (max-width: 1280px) {
        left: -24px;
      }
    }
  }
`;

export const Title = styled.h1`
  font-size: 32px;
  text-align: center;
  font-weight: ${FONT_WEIGHT.EXTRA_BOLD};
  color: ${COLORS.PRIMARY};

  @media (max-width: 1400px) {
    font-size: 28px;
  }

  @media (max-width: 700px) {
    font-size: 24px;
  }
`;

// SLIDER

export const Dots = styled.div`
  border-radius: 12px;
  padding: 12px 0;
`;

export const Dot = styled.ul`
  margin-bottom: -42px;

  li button:before {
    font-size: 12px;
    opacity: 1;
    color: #d7d7d7;
  }

  li.slick-active button:before {
    color: ${COLORS.PRIMARY};
    opacity: 1;
  }
`;

export const SlideContainer = styled(Link)`
  all: unset;
  cursor: pointer;
  width: 96%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: ${COLORS.PRIMARY};
  transition: 0.4s all;

  &:hover {
    transition: 0.4s all;

    div {
      background-size: 110% 110%;
    }
  }
`;

export const SlideImage = styled.div<BackgroundImageProps>`
  width: 100%;
  height: 380px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  transition: 0.4s all;
  background: ${({ backgroundImage }) =>
    `url(${backgroundImage ? backgroundImage : DefaultImage})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;

  @media (max-width: 1400px) {
    height: calc(((1200px * 0.3333 * 0.96) * 380) / (1400 * 0.3333));
  }

  @media (max-width: 1200px) {
    height: calc(((90vw * 0.3333 * 0.96) * 380) / (1400 * 0.3333));
  }

  @media (max-width: 950px) {
    height: calc(((90vw * 0.5 * 0.96) * 380) / (1400 * 0.3333));
  }

  @media (max-width: 700px) {
    height: calc((90vw * 380) / (1400 * 0.3333));
  }
`;

export const SlideInfo = styled.div`
  padding: 18px 24px;
  text-align: center;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border: 1px solid black;
  border-top: none;
  background-color: ${COLORS.GRAY};
`;

export const InfoTitle = styled.h1`
  font-size: 32px;
  height: 78px;
  overflow: hidden;
  font-weight: ${FONT_WEIGHT.SEMI_BOLD};

  @media (max-width: 1400px) {
    font-size: 24px;
    height: 58px;
  }
`;

export const Info = styled.p`
  margin: 12px 0;
  font-size: 22px;

  @media (max-width: 1400px) {
    font-size: 16px;
    margin: 8px 0;
  }

  &.totalValue {
    margin-top: 22px;
    @media (max-width: 1400px) {
      margin-top: 16px;
    }
  }

  b {
    font-weight: ${FONT_WEIGHT.EXTRA_BOLD};

    span {
      font-weight: ${FONT_WEIGHT.EXTRA_BOLD};
      font-size: 42px;

      @media (max-width: 1400px) {
        font-size: 38px;
      }
    }
  }
`;

export const EmptyPackages = styled.p`
  width: 100%;
  height: 708px;
  color: ${COLORS.PRIMARY};
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 38px;
  margin-top: 200px;

  @media (max-width: 1400px) {
    height: 400px;
    font-size: 32px;
  }

  @media (max-width: 950px) {
    margin-top: 140px;
    font-size: 24px;
  }
`;

export const Bloqueio = styled.div`
  position: absolute;
  margin: 12px 0 0 12px;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 12px;
  padding: 4px 8px;
  font-size: 14px;

  img {
    margin-right: 8px;
    filter: invert(22%) sepia(10%) saturate(2132%) hue-rotate(169deg)
      brightness(99%) contrast(93%);
  }

  span {
    font-weight: ${FONT_WEIGHT.BOLD};
  }
`;
