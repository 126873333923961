import { Link } from "./style";

type ContactLinkProps = {
  title: string;
  link: string;
  blank?: boolean;
  secondary?: boolean;
  fontSize?: number;
  width?: string;
};

export default function ContactLink({
  title,
  link,
  blank,
  fontSize,
  width,
  secondary = false,
}: ContactLinkProps) {
  return (
    <Link
      href={link}
      target={blank ? "_blank" : "_parent"}
      width={width}
      fontSize={fontSize}
      secondary={secondary}
    >
      {title}
    </Link>
  );
}
