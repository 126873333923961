export const packageLink = (title: string) => {
  return title
    .toLowerCase()
    .replaceAll(" ", "-")
    .replaceAll("ã", "a")
    .replaceAll("á", "a")
    .replaceAll("â", "a")
    .replaceAll("õ", "o")
    .replaceAll("ó", "o")
    .replaceAll("ô", "o")
    .replaceAll("é", "e")
    .replaceAll("ê", "e")
    .replaceAll("í", "i")
    .replaceAll("î", "i")
    .replaceAll("ç", "c")
    .replace(/[^a-z-]/g, "");
};
