import { AboutInfo, Flex } from "./style";
import AboutImg from "../../../assets/images/about.png";
import { kekData } from "../../../mocks/kekData";
import ContactLink from "../../../components/ContactLink";
import { SectionProps } from "../../../types/section";

export default function About({ referance }: SectionProps) {
  return (
    <Flex ref={referance}>
      <AboutInfo>
        <div>
          <h1>Sobre nós</h1>
          <p>
            Nosso atendimento e proximidade com nossos clientes são nossos
            diferenciais, desde 2018 somos uma empresa que entende a necessidade
            do nosso cliente e busca sempre as melhores ofertas
          </p>
          <ContactLink
            title="saiba mais"
            link={kekData.whatsapp}
            blank
            secondary
          />
        </div>
      </AboutInfo>
      <img src={AboutImg} alt="about image" />
    </Flex>
  );
}
