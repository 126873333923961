import { useState } from "react";
import {
  BackgroundImage,
  SearchBox,
  SearchContainer,
  SearchForm,
  SearchedTrips,
  Tab,
  Tabs,
  TripEmpty,
  TripItem,
} from "./style";
import { SlideType, packages } from "../../../mocks/packages";
import { SectionProps } from "../../../types/section";
import { packageLink } from "../../../utils/packageLink";
import { formatDateToISO } from "../../../utils/format";

export default function SearchTrip({ referance }: SectionProps) {
  const [openSearch, setOpenSearch] = useState(false);
  const [searchTrip, setSearchTrip] = useState("");
  const [packType, setPackType] = useState<number>(SlideType.nacional);

  const handlePackType = () => {
    if (packType === SlideType.bloqueio)
      return packages.filter(
        (pack) =>
          pack.isBloqueio &&
          new Date(formatDateToISO(pack.initialDate)) >= new Date()
      );

    if (packType === SlideType.navio)
      return packages.filter(
        (pack) =>
          pack.isNavio &&
          new Date(formatDateToISO(pack.initialDate)) >= new Date()
      );

    return packages.filter(
      (pack) =>
        pack.type === packType &&
        new Date(formatDateToISO(pack.initialDate)) >= new Date()
    );
  };

  function handleGenerateSearch() {
    return handlePackType().filter(
      (pack) =>
        pack?.title?.toLowerCase().includes(searchTrip?.toLowerCase()) &&
        new Date(formatDateToISO(pack.initialDate)) >= new Date()
    );
  }

  return (
    <BackgroundImage ref={referance}>
      <SearchContainer>
        <SearchBox>
          <Tabs>
            <Tab
              onClick={() => {
                setOpenSearch(false);
                setPackType(SlideType.nacional);
              }}
              active={packType === SlideType.nacional}
            >
              <p>Pacotes Nacionais</p>
            </Tab>
            <Tab
              onClick={() => {
                setOpenSearch(false);
                setPackType(SlideType.internacional);
              }}
              active={packType === SlideType.internacional}
            >
              <p>Pacotes Internacionais</p>
            </Tab>
            <Tab
              onClick={() => {
                setOpenSearch(false);
                setPackType(SlideType.bloqueio);
              }}
              active={packType === SlideType.bloqueio}
            >
              <p>Bloqueios</p>
            </Tab>
            <Tab
              onClick={() => {
                setOpenSearch(false);
                setPackType(SlideType.navio);
              }}
              active={packType === SlideType.navio}
            >
              <p>Cruzeiro</p>
            </Tab>
          </Tabs>
          <SearchForm>
            <label>Pesquise sua viagem</label>
            <input
              type="text"
              placeholder="Digite o destino que deseja"
              value={searchTrip}
              onChange={(e) => setSearchTrip(e.target.value)}
              onFocus={() => setOpenSearch(true)}
              onBlur={() =>
                setTimeout(() => {
                  setOpenSearch(false);
                }, 150)
              }
            />
            {openSearch && (
              <SearchedTrips>
                {handleGenerateSearch().length ? (
                  handleGenerateSearch().map((pack, index) => (
                    <TripItem
                      href={`pacote/${packageLink(pack.title)}`}
                      key={index}
                    >
                      {pack.title}
                    </TripItem>
                  ))
                ) : (
                  <TripEmpty>Nenhum pacote encontrado</TripEmpty>
                )}
              </SearchedTrips>
            )}
          </SearchForm>
        </SearchBox>
      </SearchContainer>
    </BackgroundImage>
  );
}
