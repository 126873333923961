import { PackagesProps } from "../types/packages";

import BetoCarrero from "../assets/images/packages/beto-carreiro/castelo.png";
import BetoCarreroH from "../assets/images/packages/beto-carreiro/castelo-horizontal.png";
import BetoCarreroL from "../assets/images/packages/beto-carreiro/lamina-bettocarrero.png";
import betocarreroPdf from "../assets/pdfs/betocarrero-janeiro.pdf";

import Costa from "../assets/images/packages/costa-favolosa/costa-favolosa-2.png";
import CostaH from "../assets/images/packages/costa-favolosa/costa-favolosa.png";
import CostaL from "../assets/images/packages/costa-favolosa/lamina-feriascosta.png";
import cruzeirocostaPdf from "../assets/pdfs/cruzeirocosta.pdf";

import ReveillonCosta from "../assets/images/packages/costa-favolosa/costa-navio-2.png";
import ReveillonCostaH from "../assets/images/packages/costa-favolosa/costa-navio.png";
import ReveillonCostaL from "../assets/images/packages/costa-favolosa/lamina-reveilloncosta.png";
import reveilloncruzeiroPdf from "../assets/pdfs/reveillon-cruzeiro.pdf";


import Lencois from "../assets/images/packages/lencois-maranhenses/lençois-2.png";
import LencoisH from "../assets/images/packages/lencois-maranhenses/lençois.png";
import LencoisL from "../assets/images/packages/lencois-maranhenses/lamina-saoluis.png";
import saoluislencoisPdf from "../assets/pdfs/saoluis-lencoes.pdf";

import JeriFor from "../assets/images/packages/jericoacoara/jericoacoara-2.png";
import JeriForH from "../assets/images/packages/jericoacoara/jericoacoara.png";
import JeriForL from "../assets/images/packages/jericoacoara/lamina-jerijaneiro.png";
import jerifortalezaPdf from "../assets/pdfs/jeri-fortaleza.pdf";

import Gramado from "../assets/images/packages/natal-gramado/gramado.png";
import GramadoH from "../assets/images/packages/natal-gramado/gramado-h.png";
import GramadoL from "../assets/images/packages/natal-gramado/lamina-gramado.png";
import gramadoPdf from "../assets/pdfs/gramado.pdf";

import PortoGalinhas from "../assets/images/packages/porto-de-galinhas/Porto_de_Galinhas-2.png";
import PortoGalinhasH from "../assets/images/packages/porto-de-galinhas/Porto_de_Galinhas.png";
import PortoGalinhasL from "../assets/images/packages/porto-de-galinhas/lamina-portodegalinhas.png";
import portodegalinhasPdf from "../assets/pdfs/portodegalinhas.pdf";

import ItaliaBrilhante from "../assets/images/packages/italia-brilhante/italia-brilhante.png";
import ItaliaBrilhanteH from "../assets/images/packages/italia-brilhante/italia-brilhante-h.png";
import ItaliaBrilhanteL from "../assets/images/packages/italia-brilhante/lamina-italiabrilhante.png";
import ItaliaBrilhantePdf from "../assets/pdfs/italia-brilhante.pdf";

import Natal from "../assets/images/packages/natal/natal.png";
import NatalH from "../assets/images/packages/natal/natal-h.png";
import NatalL from "../assets/images/packages/natal/lamina-natal.png";
import NatalPdf from "../assets/pdfs/natal.pdf";

import FozJaneiro from "../assets/images/packages/foz-janeiro/fozjaneiro.png";
import FozJaneiroH from "../assets/images/packages/foz-janeiro/fozjaneiro-h.png";
import FozJaneiroL from "../assets/images/packages/foz-janeiro/lamina-foz-janeiro.png";
import FozJaneiroPdf from "../assets/pdfs/fozjaneiro.pdf";

import CarnavalNatalL from "../assets/images/packages/carnavalnatal/Lamina-Carnavalnatal.png";
import CarnavalNatalPdf from "../assets/pdfs/carnaval-natal.pdf";

import CarnavalFortaleza from "../assets/images/packages/carnaval-fortaleza/carnavalfortaleza.png";
import CarnavalFortalezaH from "../assets/images/packages/carnaval-fortaleza/carnavalfortaleza-h.png";
import CarnavalFortalezaL from "../assets/images/packages/carnaval-fortaleza/laminacarnaval-fortaleza.png";
import CarnavalFortalezaPdf from "../assets/pdfs/carnaval-fortaleza.pdf";

import CarnavalPgl from "../assets/images/packages/carnaval-pgl/carnaval-pgl.png";
import CarnavalPglH from "../assets/images/packages/carnaval-pgl/carnaval-pgl-h.png";
import CarnavalPglL from "../assets/images/packages/carnaval-pgl/lamina-carnaval-pgl.png";
import CarnavalPglPdf from "../assets/pdfs/carnaval-pgl.pdf";

import FozBaixaTemporada from "../assets/images/packages/foz-baixatemporada/foz-baixa.png";
import FozBaixaTemporadaH from "../assets/images/packages/foz-baixatemporada/foz-baixa-h.png";
import FozBaixaTemporadaL from "../assets/images/packages/foz-baixatemporada/lamina-foz-baixa.png";
import FozBaixaTemporadaPdf from "../assets/pdfs/foz-baixa-temporada.pdf";

import FortalezaPromo from "../assets/images/packages/fortaleza-promo/fortaleza-promo.png";
import FortalezaPromoH from "../assets/images/packages/fortaleza-promo/fortaleza-promo-h.png";
import FortalezaPromoL from "../assets/images/packages/fortaleza-promo/lamina-fortaleza-promo.png";
import FortalezaPromoPdf from "../assets/pdfs/fortaleza-promocional.pdf";

import FeriadoMorro from "../assets/images/packages/morro-sao-paulo/morro-sp.png";
import FeriadoMorroH from "../assets/images/packages/morro-sao-paulo/morro-sp-h.png";
import FeriadoMorroL from "../assets/images/packages/morro-sao-paulo/lamina-morro-sp.png";
import FeriadoMorroPdf from "../assets/pdfs/feriado-morro-de-sao-paulo.pdf";

import MorroJaneiro from "../assets/images/packages/morro-sao-paulo/morro-sp-janeiro.png";
import MorroJaneiroH from "../assets/images/packages/morro-sao-paulo/morro-sp-janeiro-h.png";
import MorroJaneiroL from "../assets/images/packages/morro-sao-paulo/lamina-morro-sp-janeiro.png";
import MorroJaneiroPdf from "../assets/pdfs/morrospjaneiro.pdf";



import NatalJaneiro from "../assets/images/packages/natal/NATAL-JANEIRO.png";
import NatalJaneiroH from "../assets/images/packages/natal/NATAL-JANEIRO-H.png";
import NatalJaneiroL from "../assets/images/packages/natal/lamina-natal-janeiro.png";
import NatalJaneiroPdf from "../assets/pdfs/ferias-janeiro-natal.pdf";

import FortalezaJaneiro from "../assets/images/packages/fortaleza/fortaleza-janeiro.png";
import FortalezaJaneiroH from "../assets/images/packages/fortaleza/fortaleza-janeiro-h.png";
import FortalezaJaneiroL from "../assets/images/packages/fortaleza/lamina-fortaleza-janeiro.png";
import FortalezaJaneiroPdf from "../assets/pdfs/ferias-janeiro-fortaleza.pdf";

import MaceioJaneiro from "../assets/images/packages/maceio/maceio-janeiro.png";
import MaceioJaneiroH from "../assets/images/packages/maceio/maceio-janeiro-h.png";
import MaceioJaneiroL from "../assets/images/packages/maceio/lamina-maceio-janeiro.png";
import MaceioJaneiroPdf from "../assets/pdfs/maceiojaneiro.pdf";

import SalvadorJaneiro from "../assets/images/packages/salvador/salvador.png";
import SalvadorJaneiroH from "../assets/images/packages/salvador/salvador-h.png";
import SalvadorJaneiroL from "../assets/images/packages/salvador/laminasalvadorjaneiro.png";
import SalvadorJaneiroPdf from "../assets/pdfs/salvadorjaneiro.pdf";

import ChileJulho from "../assets/images/packages/chile/chile-julho.png";
import ChileJulhoH from "../assets/images/packages/chile/chile-julho-h.png";
import ChileJulhoL from "../assets/images/packages/chile/lamina-chile-julho.png";
import ChileJulhoPdf from "../assets/pdfs/chile-julho.pdf";

export enum SlideType {
  nacional,
  internacional,
  bloqueio,
  navio,
}

export const packages: PackagesProps[] = [

  {
    type: SlideType.nacional,
    isBloqueio: true,
    title: "Natal Luz Gramado",
    period: "05 noites",
    initialDate: "21/11/2024",
    finalDate: "26/11/2024",
    parcelValue: 307.0,
    qtdParcel: 12,
    entry: 0.0,
    value: 3684.0,
    include: [
      "Passagem Aérea Vitoria x Porto Alegre x Vitoria com direito a 1 mala despachada de até 23kg",
      "05 Noites de Hospedagem com café da manhã",
      "Transfer in/out - Aeroporto x Hotel x Aeroporto",
      "City Tour Gramado e Canela - Não Inclui ingressos nas entradas de parques - Regular",
      "Seguro viagem",
    ],
    description: [
      "Pacote não inclui: Taxa de Embarque, Gorjetas, despesas pessoais, alimentação, bebidas e serviços não mencionados acima.",
    ],
    policy: [
      "Pagamento à vista 3% desconto ou até 12x nos cartões de crédito sem juros (passando maquininha empresa)",
      "Para a pré-reserva, obrigatório envio de nome completo, RG, CPF e data de nascimento de todos os passageiros e preenchimento do contrato de aquisição de serviços. A Vaga só é reservada mediante pagamento integral;",
      "Valor do pacote sujeito a reajuste sem prévio aviso e mediante disponibilidade de vagas no ato da reserva;",
      "Os voos poderão sofrer alterações por parte da cia aérea (bloqueio aéreo). A tarifa aérea não permite reembolso.",
      "DOCUMENTOS PARA VIAGEM: Carteira de identidade (RG – recomenda-se emissão menos de 10 anos) em bom estado, CNH – ORIGINAL ou passaporte. Para crianças é aceito a certidão de nascimento    ORIGINAL, quando viajando com um dos pais. *Menores de idade a partir de 12 anos deverão apresentar o RG válido;",
      "Menores viajando com outras pessoas que não sejam os pais deverá apresentar autorização dos dois pais. Documentação, autorizações e vacinas exigidas para o destino é de responsabilidade do passageiro.",
    ],
    image: Gramado,
    imageH: GramadoH,
    lamina: GramadoL,
    pdf: gramadoPdf,
    dayByDay: [
      {
        day: "Informações sobre o City Tour Gramado e Canela:",
        description: [
          "Nosso City Tour Gramado e Canela é um passeio completo, com cerca de 9 horas de duração, onde você vai passar pelo principais pontos turísticos das duas cidades mais amadas da Serra Gaúcha. Quer saber tudo que você vai conhecer com nosso City Tour Gramado e Canela? Então vamos lá.",
          "Gramado – Lago Negro, Cristais de Gramado, Pórtico da Cidade e Fábrica de Chocolates. Canela – Bondinhos Aéreos, Don Colise, Parque Mundo Gelado e Catedral de Pedra de Canela (panorâmico).",
          "*O city tour poderá ser alterado de acordo com as necessidades de eventos e ou condições climáticas.",
        ],
      },
    ],
  },

  {
    type: SlideType.nacional,
    title: "Reveillon Costa Cruzeiro",
    period: "07 noites",
    initialDate: "31/12/2024",
    finalDate: "08/01/2025",
    parcelValue: 599.0,
    qtdParcel: 12,
    entry: 990.0,
    value: 8178.0,
    include: [
      "08 Dias a Bordo do Navio Costa Favolosa com todas as alimentações Inclusas",
      "Seguro de viagem",
    ],
    description: [
      "Pacote não inclui: Taxa de Embarque, Gorjetas, despesas pessoais, alimentação, bebidas e serviços não mencionados acima.",
    ],
    policy: [
      "Valor do pacote sujeito a reajuste sem prévio aviso e mediante disponibilidade de vagas no ato da reserva;",
      "Os voos poderão sofrer alterações por parte da cia aérea.",
    ],
    image: ReveillonCosta,
    imageH: ReveillonCostaH,
    lamina: ReveillonCostaL,
    pdf: reveilloncruzeiroPdf,
    isNavio: true,
    dayByDay: [],
  },

  {
    type: SlideType.nacional,
    title: "Férias de Janeiro Costa Cruzeiro",
    period: "07 noites",
    initialDate: "16/01/2025",
    finalDate: "24/01/2025",
    parcelValue: 382.0,
    qtdParcel: 12,
    entry: 990.0,
    value: 5574.0,
    include: [
      "08 Dias a Bordo do Navio Costa Favolosa com todas as alimentações Inclusas",
      "Seguro de viagem",
    ],
    description: [
      "Pacote não inclui: Taxa de Embarque, Gorjetas, despesas pessoais, alimentação, bebidas e serviços não mencionados acima.",
    ],
    policy: [
      "Valor do pacote sujeito a reajuste sem prévio aviso e mediante disponibilidade de vagas no ato da reserva;",
      "Os voos poderão sofrer alterações por parte da cia aérea.",
    ],
    image: Costa,
    imageH: CostaH,
    lamina: CostaL,
    pdf: cruzeirocostaPdf,
    isNavio: true,
    dayByDay: [],
  },

  {
    type: SlideType.nacional,
    isBloqueio: true,
    title: "Promoção - Foz do Iguaçu",
    period: "04 noites",
    initialDate: "25/03/2025",
    finalDate: "29/03/2025",
    parcelValue: 136.0,
    qtdParcel: 12,
    entry: 641.0,
    value: 2273.0,
    include: [
      "Passagem aérea Vitoria x Foz x Vitoria com a Latam com direito a 01 mala de até 23 K ",
      "04 Noites de Hospedagem conforme escolha com café;",
      "Traslados aeroporto x Hotel x aeroporto - Regular;",
      "Ida a Cataratas Brasileiras - Sem Ingresso + Parque das Aves - Sem Ingresso - Regular ;",
      "Seguro viagem.",
      "Pacote não inclui: Taxa de Embarque, Gorjetas, despesas pessoais, alimentação, bebidas e serviços não mencionados acima.",
    ],
    description: [
      " HOTEL FOZ DO IGUAÇU - TURISTICO",
      "Valor por pessoa em apto Duplo - R$ 2.177,00 + Txs de Embarque - R$ 96,00 ",
      "Entrada de R$ 641,00 + Saldo em 12 x de R$ 136,00 ",

      "Valor para apto Single - R$ 2.844,00 + Txs de Embarque - R$ 96,00",
      "Entrada de R$ 804,00 + Saldo em 12 x de R$ 178,00",

      "Valor por pessoa em apto Triplo - R$ 2.144,00 + Txs de Embarque - R$ 96,00 ",
      "Entrada de R$ 632,00 + Saldo em 12 x de R$ 134,00 ",
      " ",
      "HOTEL RAFAIN CENTRO - TURISTICO SUPERIOR",
      "Valor por pessoa em apto Duplo - R$ 2.574,00 +Txs de Embarque - R$ 96,00",
      "Entrada de R$ 738,00 + Saldo em 12 x de R$ 161,00",

      "Valor para apto Single - R$ 3.456,00 + Txs de Embarque - R$ 96,00",
      "Entrada de R$  960,00 + Saldo em 12 x de R$ 216,00",

      "Valor por pessoa em apto Triplo - R$ 2.546,00 + Txs de Embarque - R$ 96,00 ",
      "Entrada de R$ 734,00 + Saldo em 12 x de R$ 159,00",
      " ",
      "* Para valores e disponibilidade de outros hotéis, favor consulte com seu agente de viagens.",
      "* Crianças até 3 anos favor verifique o valor com seu agente. As crianças a partir de 4 anos já pagam valor normal.",
    ],
    policy: [
      "•	Para a pré-reserva, obrigatório envio de nome completo, RG, CPF e data de nascimento de todos os passageiros e assinatura do contrato de aquisição de serviços. A Vaga só é reservada mediante pagamento integral; ",
      "•	Valor do pacote sujeito a reajuste sem prévio aviso e mediante disponibilidade de vagas no ato da reserva;",
      "•	Os voos poderão sofrer alterações por parte da cia aérea (bloqueio aéreo). A tarifa aérea não permite reembolso.",
      "DOCUMENTOS PARA VIAGEM: Carteira de identidade (RG – recomenda-se emissão menos de 10 anos) em bom estado, CNH – ORIGINAL ou passaporte válido. Para crianças (0 a 11 anos) é aceito a certidão de nascimento  ORIGINAL, quando viajando com um dos pais. *Menores de idade a partir de 12 anos deverão apresentar o RG válido ou passaporte válido; *Menores viajando com outras pessoas que não sejam os pais deverá apresentar autorização dos dois pais. A ausência de documentos no embarque o passageiro não embarca causando No-show, sem possibilidade de reembolso ou crédito do valor pago. Documentação, autorizações e vacinas exigidas para o destino é de responsabilidade do passageiro.",
      "FORMAS DE PAGAMENTO:",
      "- Entrada + taxa de embarque (Pix, Transferência ou depósito) + saldo em até 9 vezes (link) ou 12 vezes (máquina) no Cartão sem juros;",
      "- Pagamento à vista 3% desconto;",
    ],
    image: FozBaixaTemporada,
    imageH: FozBaixaTemporadaH,
    lamina: FozBaixaTemporadaL,
    pdf: FozBaixaTemporadaPdf,
    dayByDay: [],
  },

  {
    type: SlideType.nacional,
    isBloqueio: true,
    title: "Férias de Janeiro Porto de Galinhas",
    period: "05 noites",
    initialDate: "19/01/2025",
    finalDate: "24/01/2025",
    parcelValue: 216.25,
    qtdParcel: 12,
    entry: 832.0,
    value: 3427.0,
    include: [
      "Passagem aérea Vitoria x Recife x Vitoria Com a Gol Com bagagem de até 23 Kilos",
      "05 Noites de Hospedagem em Porto no Hotel Jardins Prime em apto Deluxe x Similar com café",
      "Traslado aeroporto Recife x Hotel x aeroporto Recife -Regular",
      "Seguro viagem",
    ],
    description: [
      "Pacote não inclui: Taxa de Embarque, Gorjetas, despesas pessoais, alimentação, bebidas e serviços não mencionados acima.",
    ],
    policy: [
      "Valor do pacote sujeito a reajuste sem prévio aviso e mediante disponibilidade de vagas no ato da reserva;",
      "Os voos poderão sofrer alterações por parte da cia aérea.",
    ],
    image: PortoGalinhas,
    imageH: PortoGalinhasH,
    lamina: PortoGalinhasL,
    pdf: portodegalinhasPdf,
    dayByDay: [],
  },

  {
    type: SlideType.nacional,
    isBloqueio: true,
    title: "São Luís + Lençóis",
    period: "04 noites",
    initialDate: "14/11/2024",
    finalDate: "18/11/2024",
    parcelValue: 251,
    qtdParcel: 12,
    entry: 975.0,
    value: 3987.0,
    include: [
      "Passagem Aérea Vitoria x São Luís x Vitoria com 01 bagagem despachada de até 23kg",
      "Traslado aeroporto Slz x Hotel de Slz - Regular",
      "01 noite em São Luís Hotel Costa Atlântico ou Similar com café da manhã",
      "Traslados Hotel São Luís x Barreirinhas saída a partir das 07:00 h - Regular",
      "02 Noites de Hospedagem em Barreirinhas na Pousada do Rio com café da manhã",
      "Tour em veículo 4 x 4 aos grandes Lençóis - Saída a partir das 13:30 h - Regular",
      "Tour de Lancha pelo Rio Preguiças até Caburé - Saída a partir das 08:00h - Regular",
      "Traslado Pousada Barreirinhas x Hotel São Luís - A Partir das as 09:00 h - Regular",
      "01 noite em São Luís Hotel Costa Atlântico ou Similar com café",
      "Traslado Hotel São Luís x aeroporto – Regular",
      "Seguro viagem",
    ],
    description: [
      "Pacote não inclui: Taxa de Embarque, Gorjetas, despesas pessoais, alimentação, bebidas e serviços não mencionados acima.",
    ],
    policy: [
      "Valor do pacote sujeito a reajuste sem prévio aviso e mediante disponibilidade de vagas no ato da reserva;",
      "Os voos poderão sofrer alterações por parte da cia aérea.",
    ],
    image: Lencois,
    imageH: LencoisH,
    lamina: LencoisL,
    pdf: saoluislencoisPdf,
    dayByDay: [
      {
        day: "INFORMAÇÕES SOBRE OS PASSEIOS:",
        description: [],
      },
      {
        day: "Tour em veículo 4 x 4 aos grandes Lençóis - Saída a partir das 13:30 h – Regular",
        description: [
          "Os Lençóis Maranhenses ficam no litoral oriental do Maranhão e compreende os municípios de Humberto de Campos, Primeira Cruz, Santo Amaro e Barreirinhas, este último sendo o principal portão de entrada para esta fantástica beleza natural.",
          "Seu maior atrativo, é o Parque Nacional dos Lençóis Maranhenses, às margens do Rio Preguiças. São 155 mil hectares de paisagem deslumbrantes, cheio de dunas com até 40 metros de altura e lagoas de água doce e cristalina.",
          "O Parque é considerado uma formação geológica rara no planeta, apresentando um ecossistema único e riquíssimo. As dunas chegam do litoral adentrando em até 25 Km da costa e as inúmeras e límpidas lagoas se formam com as chuvas do período chuvoso que vai de dezembro até Julho.",
        ],
      },

      {
        day: "Destaques:",
        description: [
          "Dunas dos Lençóis Maranhenses",
          "Banho nas lagoas cristalinas (nos períodos disponíveis)",
          "Aventura em 4x4",
        ],
      },

      {
        day: "Saída entre 13h30 e 14h30 (de acordo com o Hotel)",
        description: [
          "Saída em veículo 4x4 tipo jardineira para travessia do Rio Preguiças feita em balsa em seguida por trilhas de areias, lagos e pequenas dunas por aproximadamente 50 minutos até chegarmos ao Parque Nacional dos Lençóis Maranhenses. O veículo fará parada no “pé” das dunas para subirmos e dar de encontro com o explendor das lagoas, fazendo paradas para banho em várias lagoas (dependendo do período do ano). Ao final da tarde, seguiremos para a duna mais alta para contemplarmos o belíssimo por do sol nas dunas, um dos momentos altos do passeio, perfeito para lindas fotos.",
        ],
      },

      {
        day: "Tour de Lancha pelo Rio Preguiças ate Caburé - Saida a partir das 08:00h - Regular",
        description: [
          "Compreendido pelos municípios de Barreirinhas, Paulino Neves e Tutóia, os Pequenos Lençóis fica localizado na margem direita do Rio Preguiças, seguindo pelo litoral até a cidade de Tutóia, na região do Delta das Américas. Formada por areias douradas e fofa, conta com uma mata nativa em alguns locais. O Rio Preguiças, que segue nesta região possui uma rica vida animal e belíssimas regiões de dunas e locais para banhos principalmente para crianças e idosos já que suas águas são tranquilas e preguiçosas como seu nome.",
        ],
      },

      {
        day: "Destaques:",
        description: [
          "Dunas e Lagoas dos Pequenos Lençóis",
          "Macacos Prego em Vassouras",
          "Farol Preguiças em Mandacaru",
          "Praia de Caburé",
        ],
      },
      {
        day: "Saída entre 08h00 e 08h45 (de acordo com o hotel)",
        description: [
          "Pela manhã, saída em veículo do hotel (de acordo com a localização) até o porto na beira rio para embarque em lancha voadeira onde percorreremos o majestoso Rio Preguiças com suas águas calmas conhecendo um incrível atalho de aproximadamente 800m cavado pelos escravos nos tempos do Brasil colonia. Seguiremos pelo Rio até nossa primeira parada (40 minutos) na comunidade de Vassouras com uma pequena estrutura de restaurante, aqui temos as dunas de areias amareladas onde podemos tomar banho nas lagoas formadas no período de chuva, e também visualizar o imenso parque aeólico da região, e de quebra conhecer os famosos moradores dos mangues locais: os macacos pregos. Nossa próxima parada(30 minutos é o vilarejo de Mandacarú, onde podemos visitar o imponente Farol Preguiças, com possibilidade de subir suas escadas (160 degraus) para tirar lindas fotos da região e depois tomar uma deliciosa capirinha de frutas regionais vendido na descida da lancha. A última parada será em Caburé, uma região formada por dunas separando o Rio Preguiças e o oceano Atlántico com suas praias desertas, aqui faremos parada para almoço (não incluso) banho de Rio ou Mar. Por volta das 14h00 retornaremos a Barreirinhas com chegada estimada as 15h00.",
        ],
      },
    ],
  },

  {
    type: SlideType.nacional,
    isBloqueio: true,
    title: "Fortaleza + Jericoacoara",
    period: "06 noites",
    initialDate: "29/01/2025",
    finalDate: "05/02/2025",
    parcelValue: 265.0,
    qtdParcel: 12,
    entry: 1027.5,
    value: 4207.5,
    include: [
      "Passagem aérea Vitoria x Fortaleza x Vitoria com a Gol com direito a 01 mala despachada de até 23 kg.",
      "01 Noite de Hospedagem em Fortaleza no Hotel Maredomus x similar em apto Std com café da manhã",
      "Traslado aeroporto x Hotel em Fortaleza - Regular",
      "Traslado Hotel em Fortaleza x Jeri em Onibus - Regular saída as 07:00 h da manha + Jardineira com duração aproximada de 05 Horas",
      "04 Noites de Hospedagem em Jeri na Pousada Numei x similar em apto Std com café",
      "Traslado Jeri x Fortaleza Jardineira x Hotel Regular - Saída as 11:00 h com duração aproximadamente de 05 Horas",
      "01 Noite de Hospedagem em Fortaleza no Hotel Maredomus x similar em apto Std com café da manhã",
      "Traslado Hotel Fortaleza x Aeroporto - Regular",
      "Seguro Viagem",
    ],
    description: [
      "Pacote não inclui: Taxa de Embarque, Gorjetas, despesas pessoais, alimentação, bebidas e serviços não mencionados acima.",
    ],
    policy: [
      "FORMAS DE PAGAMENTO: ",
      "- Entrada de R$ 1.027,50 + Saldo em 12 x sem Juros nos cartões de Credito - R$ 265,00",
      "- incluso acima taxa de embarque R$ 97,50 por pessoa, que deverá ser paga com a entrada.",
      "Condições Gerais:",
      "•	Forma de Pagamento: Entrada + taxa de embarque (Pix, Transferência ou depósito) + saldo em até 9 vezes (link) ou 12  vezes (maquina) no Cartão s/ juros; Pagamento à vista 3% desconto;",
      "•	Para a pré-reserva, obrigatório envio de nome completo, RG, CPF e data de nascimento de todos os passageiros; e preenchimento do contrato de aquisição de serviços. A Vaga só é reservada mediante pagamento integral;",
      "•	Valor do pacote sujeito a reajuste sem prévio aviso e mediante disponibilidade de vagas no ato da reserva;",
      "•	Os voos poderão sofrer alterações por parte da cia aérea (bloqueio aéreo).",
      "DOCUMENTOS PARA VIAGEM: Carteira de identidade (RG – recomenda-se emissão menos de 10 anos) em bom estado ou CNH - ORIGINAL. Para crianças é aceito a certidão de nascimento   ORIGINAL, quando viajando com um dos pais. *Menores de idade a partir de 12 anos deverão apresentar o RG; *Menores viajando com outras pessoas que não sejam os pais deverá apresentar autorização dos dois pais. Documentação, autorizações e vacinas exigidas para o destino é de responsabilidade do passageiro.",
    ],
    image: JeriFor,
    imageH: JeriForH,
    lamina: JeriForL,
    pdf: jerifortalezaPdf,
    dayByDay: [],
  },

  {
    type: SlideType.internacional,
    title: "Itália Brilhante",
    period: "8 noites",
    initialDate: "12/10/2024",
    finalDate: "21/10/2024",
    parcelValue: 249.0,
    qtdParcel: 10,
    entry: 1066.0,
    value: 3555.0,
    include: [
      "Passagem aérea em classe econômica com cia Air Europa com 1 bagagem despachada de 23kg;",
      "Transporte durante todo o roteiro europeu em ônibus confortável com WI-FI incluso;",
      "Traslado na chegada e saída do Aeroporto ao Hotel e vice-versa;",
      "Guia acompanhante;",
      "Hospedagem com café da manhã tipo buffet nos Hotéis indicados;",
      "Todas as taxas turísticas nas cidades de pernoites;",
      "Guias locais para as visitas das cidades tal como indicado no roteiro;",
      "02 Noites de hospedagem em Milão com café da manhã;",
      "01 Noite de hospedagem em Veneza com café da manhã;",
      "02 Noites de hospedagem em Florença com café da manhã;",
      "03 Noites de hospedagem em Roma com café da manhã;",
      "Almoço em Sirmione, Veneza, Florença e Roma;",
      "Passeio de Gôndola;",
      "Visita aos Museus Vaticanos;",
      "Seguro TOTAL Trabax.",
    ],
    description: [
      "Não está incluso no pacote: Gorjetas, Entradas não mencionadas no roteiro, Refeições não mencionadas no roteiro ",
    ],
    policy: [
      "Valores em EUR e deverão ser convertido no cambio do dia no ato do fechamento",
      "Valores por pessoa em apto duplo sujeito a disponibilidade no ato da reserva",
      "Para reservar necessário Passaporte",
    ],
    image: ItaliaBrilhante,
    imageH: ItaliaBrilhanteH,
    lamina: ItaliaBrilhanteL,
    pdf: ItaliaBrilhantePdf,
    dayByDay: [
      {
        day: "1º DIA 12/10: VITORIA - GUARULHOS – MADRID",
        description: [
          "Apresentação as 10:50 no aeroporto de Guarulhos (GRU) para embarque no voo Air Europa 058 saída 13:50 com conexão em Madri.",
        ],
      },
      {
        day: "2° DIA 13/10: MADRID – MILÃO",
        description: [
          "Chegada em Madrid as 05:00 para conexão com voo Air Europa 1065 partindo as 07:30 com destino a Milão chegada as 09:35 e traslado ao Hotel. Dia livre, sugerimos aproveitar o tempo para admirar sua fascinante Catedral gótica na Praça Duomo e visitar a Galeria de Vittorio Emanuele II. Hospedagem.",
        ],
      },
      {
        day: "3° DIA 14/10: MILÃO",
        description: [
          "Café da Manhã buffet. Dia Livre para atividades independentes. Aproveite!",
        ],
      },
      {
        day: "4° DIA 15/10: MILÃO – LAGO DI GARDA – VERONA – VENEZA (345 KM.)",
        description: [
          "Café da manhã buffet. Hoje iremos ao centro de Milão, onde teremos um curto tempo livre para ver sua fascinante Catedral Gótica na praça que leva seu nome Duomo di Milano, e passear pela Galleria de Vittorio Emanuele II. Saída de Milão para o Lago de Garda, em suas margens encontramos bonitas cidades como Sirmione, parada para almoço incluso. Continuaremos até chegar em Verona onde poderemos visitar sua Arena e relembrar a trágica e romântica estória de Romeu e Julieta. Chegada a Veneza (Mestre). Hospedagem.",
        ],
      },
      {
        day: "5° DIA 16/10: VENEZA – FLORENÇA (258 KM.)",
        description: [
          "Café da manhã buffet. Traslado para embarcar no Vaporetto que nos levará até a Praça de São Marcos. Visita a pé com guia local. Veremos a Ponte dos Suspiros, a Basílica de São Marcos, o Palácio Ducal e finalizaremos em uma fábrica de vidro, onde poderemos observar a técnica do sopro. Almoço incluso em um restaurante típico no centro. Após almoço passeio nas famosas Gôndolas Venezianas. Continuação a Florença. Chegada e Hospedagem.",
        ],
      },
      {
        day: "6° DIA 17/10: FLORENÇA",
        description: [
          "Café da manhã buffet. Visita panorâmica de Florença, iniciaremos pelo mirante da Praça Michelangelo, onde desfrutaremos de uma espetacular vista da cidade. Continuaremos com a visita a pé com guia local, veremos: a Praça Duomo, onde está a Catedral de Florença e a impressionante cúpula de Brunelleschi, o Campanile de Giotto e o Battistero de San Giovanni. Seguiremos até alcançar a Ponte Vecchio, o lugar com maior encanto de toda Florença passando pela Praça della Signoria e o Palazzo Vecchio. Parada para almoço incluso. Recomendamos percorrer suas maravilhosas ruas e visitar opcionalmente um dos seus grandes museus. Hospedagem.",
        ],
      },
      {
        day: "7° DIA 18/10: FLORENÇA – PISA – SIENA – ROMA (446 KM.)",
        description: [
          "Café da manhã buffet. Saída para Pisa, para poder conhecer a famosa Torre Inclinada localizada na Praça dos Milagres (Piazza dei Miracoli), junto a Catedral e o Batistério. Tempo livre. Saída para Siena, a joia da Toscana, com sua Praça do Campo onde cada ano é celebrado as tradicionais corridas de cavalos durante a Festa do Palio. Tempo livre. Continuaremos nossa rota a Roma. Chegada e Hospedagem.",
        ],
      },
      {
        day: "8° DIA 19/10: ROMA",
        description: [
          "Café da manhã buffet. Hoje realizaremos a visita panorâmica com guia local da cidade, passearemos por suas ruas e avenidas, passando por lugares destacados: Praça de Veneza, Via Venetto, os Foros Romanos, O Coliseu (exterior), Arco Constantino, o Castelo de St. Angelo, Museus Vaticanos, te surpreenderá a famosa Capela Sixtina e o exterior da Basílica de São Pedro. Parada para almoço incluso. Tarde livre, recomendamos a visita opcional, Roma Barroca incluindo a Fonte de Trevi. Hospedagem.",
        ],
      },
      {
        day: "9° DIA 20/10: ROMA",
        description: [
          "Café da manhã buffet. Dia livre para seguir descobrindo esta fascinante cidade ou realizar alguma excursão opcional. Recomendamos a visita opcional Nápoles e Capri. Uma viagem para desfrutar do Golfo de Nápoles e uma de suas mais famosas e bonitas ilhas. Capri é encantadora por suas maravilhosas belezas naturais, sua história milenária, seu clima agradável e sua paisagem. Hospedagem.",
        ],
      },
      {
        day: "10° DIA 21/10: ROMA",
        description: [
          "Café da manhã. Traslado ao aeroporto de ROMA as 15:25 para embarque no voo Air Europa 1048 saída as 18:25 para Madrid chegada as 21:00 e conexão com voo Air Europa 057 com saída as 23:55 com destino a Guarulhos.",
        ],
      },
    ],
  },

  {
    type: SlideType.nacional,
    isBloqueio: true,
    title: "Natal - Cidade do Sol",
    period: "06 noites",
    initialDate: "31/10/2024",
    finalDate: "06/11/2024",
    parcelValue: 148,
    qtdParcel: 12,
    entry: 562.0,
    value: 2246.0,
    include: [
      "Passagem aérea Vitoria x Natal x Vitoria com Latam com Direito a 01 mala despachada de ate 23kg;",
      "06 Noites de Hospedagem em Natal Conforme escolha, com café da manhã;",
      "Traslados aeroporto x Hotel x aeroporto - Regular;",
      "City Tour Natal com Praia de Camurupim - Regular;",
      "Seguro Viagem.",
    ],
    description: [
      "Pacote não inclui: Taxa de Embarque, Gorjetas, despesas pessoais, alimentação, bebidas e serviços não mencionados acima.",
    ],
    policy: [
      "FORMAS DE PAGAMENTO:",
      "Entrada + taxa de embarque (Pix, Transferência ou depósito) + saldo em até 9 vezes (link) ou 12 vezes (maquina) no Cartão s/ juros; Pagamento à vista 3% desconto;",
      "Aram Ponta Negra em apto Std - Entrada de R$ 562,00 + Saldo em ate 12 x de R$ 148,00",
      "Hotel Pontalmar em apto Luxo -   Entrada de R$ 679,00 + Saldo em até 12 x de R$ 178,00",
      "Para a pré-reserva, obrigatório envio de nome completo, RG, CPF e data de nascimento de todos os passageiros; e preenchimento do contrato de aquisição de serviços. A Vaga só é reservada mediante pagamento integral;",
      "Valor do pacote sujeito a reajuste sem prévio aviso e mediante disponibilidade de vagas no ato da reserva;",
      "Os voos poderão sofrer alterações por parte da cia aérea (bloqueio aéreo). A tarifa aérea não permite reembolso.",
      "DOCUMENTOS PARA VIAGEM: Carteira de identidade (RG – recomenda-se emissão menos de 10 anos) em bom estado, CNH – ORIGINAL ou passaporte. Para crianças é aceito a certidão de nascimento    ORIGINAL, quando viajando com um dos pais. *Menores de idade a partir de 12 anos deverão apresentar o RG válido;",
      "Menores viajando com outras pessoas que não sejam os pais deverá apresentar autorização dos dois pais. Documentação, autorizações e vacinas exigidas para o destino é de responsabilidade do passageiro.",
    ],
    image: Natal,
    imageH: NatalH,
    lamina: NatalL,
    pdf: NatalPdf,
    dayByDay: [],
  },

  {
    type: SlideType.nacional,
    isBloqueio: true,
    title: "Beto Carrero - Férias de Janeiro",
    period: "4 noites",
    initialDate: "25/01/2025",
    finalDate: "29/01/2025",
    parcelValue: 206.0,
    qtdParcel: 12,
    entry: 916.0,
    value: 3388.0,
    include: [
      "Passagem aérea Vitoria x Navegantes x Vitoria cia Latam com Direito a 01 mala despachada de ate 23 kg;",
      "04 Noites de Hospedagem em Balneário no Hotel Açores em apto Std com café da manhã;",
      "Traslados aeroporto x Hotel x aeroporto - Regular;",
      "02 Dias de Parque Beto Carrero com Ingresso;",
      "Seguro de viagem",
      "Valor por pessoa:",
    ],
    description: [
      "Pacote não inclui: Taxa de Embarque, Gorjetas, despesas pessoais, alimentação, bebidas e serviços não mencionados acima.",
      "Valor por pessoa em apto Duplo - R$ 3.297,00   + Txs de R$ 91,00",
      "Entrada de R$ 916,00 + Saldo em até 12 x de R$ 206,00",
      "",
      "Valor por pessoa em apto Triplo - R$ 2.947,50 + Txs de R$ 91,00",
      "Entrada de R$ 830,00 + Saldo em até 12 x de R$ 184,00",
      "",
      "Criança de 00 a 01 ano e 11 Meses - Free",
      "Criança de 02 a 04 anos com 02 Adultos - R$ 1.180,00 + Txs de R$ 91,00,",
      "Entrada de R$ 386,00 + Saldo em até 12 x de R$ 73,75 ",
      "",
      "Criança de  05 anos  com 02 Adultos - R$  1.934,00  + Txs de R$ 91,00",
      "Entrada de R$ 573,00 + Saldo em até 12 x de R$ 121,00",
      "",
      "Para valores e disponibilidade de outros hotéis, favor consulte com seu agente de viagens."
    ],

    policy: [
      "FORMAS DE PAGAMENTO: ",
      "- Entrada + taxa de embarque (Pix, Transferência ou depósito) + saldo em até 9 vezes (link) ou 12 vezes (maquininha) no Cartão s/ juros; ",
      "- Pagamento à vista 3% desconto.",
      "Para a pré-reserva, obrigatório envio de nome completo, RG, CPF e data de nascimento de todos os passageiros; e preenchimento do contrato de aquisição de serviços. A Vaga só é reservada mediante pagamento integral;",
      "Valor do pacote sujeito a reajuste sem prévio aviso e mediante disponibilidade de vagas no ato da reserva;",
      "Os voos poderão sofrer alterações por parte da cia aérea (bloqueio aéreo).",
      "DOCUMENTOS PARA VIAGEM: Carteira de identidade (RG – recomenda-se emissão menos de 10 anos) em bom estado ou CNH - ORIGINAL. Para crianças é aceito a certidão de nascimento original, quando viajando com um dos pais. *Menores de idade a partir de 12 anos deverão apresentar o RG;",
      "Menores viajando com outras pessoas que não sejam os pais deverá apresentar autorização dos dois pais. Documentação, autorizações e vacinas exigidas para o destino é de responsabilidade do passageiro.",
    ],
    image: BetoCarrero,
    imageH: BetoCarreroH,
    lamina: BetoCarreroL,
    pdf: betocarreroPdf,
    dayByDay: [],
  },

  {
    type: SlideType.nacional,
    isBloqueio: true,
    title: "Carnaval em Natal/RN",
    period: "5 noites",
    initialDate: "28/02/2025",
    finalDate: "05/03/2025",
    parcelValue: 255.0,
    qtdParcel: 12,
    entry: 988.0,
    value: 3956.0,
    include: [
      "Passagem aérea Vitoria x Natal x Vitoria com Azul com Direito a 01 mala de ate 10kg;",
      "05 Noites de Hospedagem em Natal no Hotel Valencia em apto Std com cama de casal com café da manhã",
      "Traslados aeroporto x Hotel x aeroporto - Regular ",
      "City Tour Natal com Praia de Camurupim - Regular",
      "Seguro Viagem",
    ],
    description: [
      "Pacote não inclui: Taxa de Embarque, Gorjetas, despesas pessoais, alimentação, bebidas e serviços não mencionados acima. Para valores e disponibilidade de outros hotéis, favor consulte com seu agente de viagens.",
    ],

    policy: [
      "FORMAS DE PAGAMENTO: ",
      "- Entrada + taxa de embarque (Pix, Transferência ou depósito) + saldo em até 9 vezes (link) ou 12 vezes (maquina) no Cartão s/ juros; Pagamento à vista 3% desconto;",
      "Valor por pessoa em apto Duplo - R$ 3.956,00 + Txs de Embarque - R$ 92,00 ",
      "Entrada de R$ 988,00 + Saldo em ate 12 x sem Juros nos cartões - Via maquineta ou em ate 10 x via link - R$ 255,00 ",
      "Condições Gerais:",
      "•	Para a pré-reserva, obrigatório envio de nome completo, RG, CPF e data de nascimento de todos os passageiros; e preenchimento do contrato de aquisição de serviços. A Vaga só é reservada mediante pagamento integral;",
      "•	Valor do pacote sujeito a reajuste sem prévio aviso e mediante disponibilidade de vagas no ato da reserva;",
      "•	Os voos poderão sofrer alterações por parte da cia aérea (bloqueio aéreo). A tarifa aérea não permite reembolso.",
      "DOCUMENTOS PARA VIAGEM: Carteira de identidade (RG – recomenda-se emissão menos de 10 anos) em bom estado, CNH – ORIGINAL ou passaporte. Para crianças é aceito a certidão de nascimento    ORIGINAL, quando viajando com um dos pais. *Menores de idade a partir de 12 anos deverão apresentar o RG válido; ",
      "Menores viajando com outras pessoas que não sejam os pais deverá apresentar autorização dos dois pais. Documentação, autorizações e vacinas exigidas para o destino é de responsabilidade do passageiro.",
    ],
    image: Natal,
    imageH: NatalH,
    lamina: CarnavalNatalL,
    pdf: CarnavalNatalPdf,
    dayByDay: [],
  },

  {
    type: SlideType.nacional,
    isBloqueio: true,
    title: "Foz do Iguaçu nas Ferias",
    period: "05 noites",
    initialDate: "29/09/2025",
    finalDate: "03/10/2025",
    parcelValue: 149.25,
    qtdParcel: 12,
    entry: 575.0,
    value: 2366.0,
    include: [
      "Passagem Aérea Vitoria x Foz x Vitoria com a Gol com Bagagem de até 10 kg;",
      "05 Noites de Hospedagem no Hotel Bogari x Similar em apto Luxo com café;",
      "Traslados aeroporto x Hotel x aeroporto - Regular;",
      "Ida a Cataratas Brasileiras - Sem Ingresso - Regular;",
      "Seguro viagem",
    ],
    description: [
      "Pacote não inclui: Taxa de Embarque, Gorjetas, despesas pessoais, alimentação, bebidas e serviços não mencionados acima.",
    ],
    policy: [
      "FORMAS DE PAGAMENTO:",
      "- Entrada de R$ 575,00 + Saldo em 12 x sem Juros nos cartões de Credito - R$ 149,25",
      "- incluir acima taxa de embarque R$ 96,00 por pessoa, que deverá ser paga com a entrada.",
      "Condições Gerais:",
      "•	Forma de Pagamento: Entrada + taxa de embarque (Pix, Transferência ou depósito) + saldo em até 9 vezes (link) ou 12  vezes (maquina) no Cartão s/ juros; Pagamento à vista 3% desconto;",
      "•	Para a pré-reserva, obrigatório envio de nome completo, RG, CPF e data de nascimento de todos os passageiros; e preenchimento do contrato de aquisição de serviços. A Vaga só é reservada mediante pagamento integral;",
      "•	Valor do pacote sujeito a reajuste sem prévio aviso e mediante disponibilidade de vagas no ato da reserva;",
      "•	Os voos poderão sofrer alterações por parte da cia aérea (bloqueio aéreo).",
      "DOCUMENTOS PARA VIAGEM: Carteira de identidade (RG – recomenda-se emissão menos de 10 anos) em bom estado ou CNH - ORIGINAL. Para crianças é aceito a certidão de nascimento   ORIGINAL, quando viajando com um dos pais. *Menores de idade a partir de 12 anos deverão apresentar o RG; *Menores viajando com outras pessoas que não sejam os pais deverá apresentar autorização dos dois pais. Documentação, autorizações e vacinas exigidas para o destino é de responsabilidade do passageiro.",
    ],
    image: FozJaneiro,
    imageH: FozJaneiroH,
    lamina: FozJaneiroL,
    pdf: FozJaneiroPdf,
    dayByDay: [],
  },

  {
    type: SlideType.nacional,
    isBloqueio: true,
    title: "Carnaval em Fortaleza",
    period: "4 noites",
    initialDate: "01/03/2025",
    finalDate: "05/03/2025",
    parcelValue: 207.0,
    qtdParcel: 12,
    entry: 927.0,
    value: 3411.0,
    include: [
      "Passagem aérea Vitoria x Fortaleza x Vitoria com a Gol com direito a 01 mala de ate 10kg;",
      "04 Noites de Hospedagem conforme escolha com café da manhã;",
      "Traslados aeroporto x Hotel x aeroporto - Regular;",
      "Passeio a Morro Branco e Praia da Fontes - Regular;",
      "Seguro Viagem",
    ],
    description: [
      "Pacote não inclui: Taxa de Embarque, Gorjetas, despesas pessoais, alimentação, bebidas e serviços não mencionados acima. Para valores e disponibilidade de outros hotéis, favor consulte com seu agente de viagens.",
      " ",
      "HOTEL PRAIA CENTRO - TURISTICO EM APTO STANDARD",
      " Valor por pessoa em apto Duplo - R$ 3.311,00 + Txs de Embarque - R$ 100,00",
      " Entrada de R$ 927,00 + Saldo em 12 x de R$ 207,00",

      " Valor para apto Single - R$ 3.976,00 + Txs de Embarque - R$ 100,00",
      " Entrada de R$ 1.094,00 + Saldo em 12 x de R$ 248,50",

      " Valor por pessoa em apto Triplo - R$ 3.277,50 + Txs de Embarque - R$ 100,00",
      " Entrada de R$ 917,50 + Saldo em 12 x de R$ 205,00 ",
      " ",
      "BOURBON FORTALEZA HOTEL - SUPERIOR - EM APTO SUPERIOR ",
      " Valor por pessoa em apto Duplo - R$ 3.880,00 + Txs de Embarque - R$ 100,00",
      " Entrada de R$ 1.070,00 + Saldo em 12 x de R$ 242,50",

      " Valor para apto Single - R$ 4.752,00 + Txs de Embarque - R$ 100,00",
      " Valor para apto Single - R$ 4.752,00 + Txs de Embarque - R$ 100,00",
      
      " Valor por pessoa em apto Triplo - R$ 3.800,00 + Txs de Embarque - R$ 100,00",
      " Entrada de R$ 1.050,00 + Saldo em 12 x de R$ 237,50",
      " ",
      "VILA GALE CUMBUCO - RESORT - EM APTO - ALL INCLUSIVE ",
      " Valor por pessoa em apto Duplo - R$ 5.824,00 + Txs de Embarque - R$ 100,00",
      " Entrada de R$ 1.556,00 + Saldo em 12 x de R$ 364,00",

      " Valor para apto Single - R$ 8.511,50 + Txs de Embarque - R$ 100,00",
      " Entrada de R$ 2.227,50 + Saldo em 12 x de R$ 532,00",

      " Valor por pessoa em apto Triplo - R$ 5.704,00 + Txs de Embarque - R$ 100,00",
      " Entrada de R$ 1.526,00 + Saldo em 12 x de R$ 356,50",

      "* para valores e disponibilidade de outros hotéis, favor consulte com seu agente de viagens.",
      "* crianças até 3 anos favor verifique o valor com seu agente. As crianças a partir de 4 anos já pagam valor normal",
    ],

    policy: [
      "FORMAS DE PAGAMENTO: ",
      "- Entrada + taxa de embarque (Pix, Transferência ou depósito) + saldo em até 9 vezes (link) ou 12 vezes (maquina) no Cartão s/ juros; Pagamento à vista 3% desconto;",
      "Valor por pessoa em apto Duplo - R$ 3.956,00 + Txs de Embarque - R$ 92,00 ",
      "Entrada de R$ 988,00 + Saldo em ate 12 x sem Juros nos cartões - Via maquineta ou em ate 10 x via link - R$ 255,00 ",
      "Condições Gerais:",
      "•	Para a pré-reserva, obrigatório envio de nome completo, RG, CPF e data de nascimento de todos os passageiros; e preenchimento do contrato de aquisição de serviços. A Vaga só é reservada mediante pagamento integral;",
      "•	Valor do pacote sujeito a reajuste sem prévio aviso e mediante disponibilidade de vagas no ato da reserva;",
      "•	Os voos poderão sofrer alterações por parte da cia aérea (bloqueio aéreo). A tarifa aérea não permite reembolso.",
      "DOCUMENTOS PARA VIAGEM: Carteira de identidade (RG – recomenda-se emissão menos de 10 anos) em bom estado, CNH – ORIGINAL ou passaporte. Para crianças é aceito a certidão de nascimento    ORIGINAL, quando viajando com um dos pais. *Menores de idade a partir de 12 anos deverão apresentar o RG válido; ",
      "Menores viajando com outras pessoas que não sejam os pais deverá apresentar autorização dos dois pais. Documentação, autorizações e vacinas exigidas para o destino é de responsabilidade do passageiro.",
    ],
    image: CarnavalFortaleza,
    imageH: CarnavalFortalezaH,
    lamina: CarnavalFortalezaL,
    pdf: CarnavalFortalezaPdf,
    dayByDay: [],
  },

  {
    type: SlideType.nacional,
    isBloqueio: true,
    title: "Carnaval em Porto de Galinhas",
    period: "4 noites",
    initialDate: "02/03/2025",
    finalDate: "06/03/2025",
    parcelValue: 206.50,
    qtdParcel: 12,
    entry: 928.0,
    value: 3406.0,
    include: [
      "Passagem aérea Vitoria x Recife x Vitoria com a Latam com direito a 01 mala de até 10kg;",
      "04 Noites de Hospedagem conforme escolha com café da manhã;",
      "Traslados aeroporto x Hotel x aeroporto - Regular;",
      "Seguro Viagem",
    ],
    description: [
      "Pacote não inclui: Taxa de Embarque, Gorjetas, despesas pessoais, alimentação, bebidas e serviços não mencionados acima. Para valores e disponibilidade de outros hotéis, favor consulte com seu agente de viagens.",
      " ",
      "POUSADA ÁGUA VIVA - TURISTICA - APTO B SUITE PREMIUM ",
      " Valor por pessoa em apto Duplo - R$ 3.304,00 + Txs de Embarque - R$ 102,00",
      " Entrada de R$ 928,00 + Saldo em 12 x de R$ 206,50",

      " Valor para apto Single - R$ 4.616,00 + Txs de Embarque - R$ 102,00",
      " Entrada de R$ 1.256,00 + Saldo em 12 x de R$ 288,50",

      " Valor por pessoa em apto Triplo - R$ 3.280,00+ Txs de Embarque - R$ 102,00",
      " Entrada de R$ 922,00 + Saldo em 12 x de R$ 205,00",
      " ",
      "POUSADA MARIE CLARIE FLATS - TURISTICA - APTO FLAT COMFORT",
      " Valor por pessoa em apto Duplo - R$ 3.816,00 + Txs de Embarque - R$ 102,00;",
      " Entrada de R$ 1056,00 + Saldo em 12 x de R$ 238,50;",

      " Valor para apto Single - R$ 5.416,00 + Txs de Embarque - R$ 102,00;",
      " Entrada de R$ 1.456,00 + Saldo em 12 x de R$ 338,50;",

      " Valor por pessoa em apto Triplo - R$ 3.760,00 + Txs de Embarque - R$ 100,00;",
      " Entrada de R$ 1.042,00 + Saldo em 12 x de R$ 235,00.",
      " ",
      "HOTEL BEACH CLASS MURO ALTO BY AFT - SUPERIOR - APTO FAMILIA",
      " Valor por pessoa em apto Duplo - R$ 5.507,00   + Txs de Embarque - R$ 102,00;",
      " Entrada de R$ 1.481,00   + Saldo em 12 x de R$ 344,00",

      " Valor para apto Single - R$ 8.536,00 + Txs de Embarque - R$ 102,00;",
      " Entrada de R$ 2.236,00   + Saldo em 12 x de R$ 533,50;",

      " Valor por pessoa em apto Triplo - R$ 4.376,00 + Txs de Embarque - R$ 102,00",
      " Entrada de R$ 1.190,00 + Saldo em 12 x de R$ 274,00",
      "* para valores e disponibilidade de outros hotéis, favor consulte com seu agente de viagens.",
      "* crianças até 3 anos favor verifique o valor com seu agente. As crianças a partir de 4 anos já pagam valor normal",
    ],

    policy: [
      "FORMAS DE PAGAMENTO: ",
      "- Entrada + taxa de embarque (Pix, Transferência ou depósito) + saldo em até 9 vezes (link) ou 12 vezes (maquina) no Cartão s/ juros; Pagamento à vista 3% desconto;",
      "Valor por pessoa em apto Duplo - R$ 3.956,00 + Txs de Embarque - R$ 92,00 ",
      "Entrada de R$ 988,00 + Saldo em ate 12 x sem Juros nos cartões - Via maquineta ou em ate 10 x via link - R$ 255,00 ",
      "Condições Gerais:",
      "•	Para a pré-reserva, obrigatório envio de nome completo, RG, CPF e data de nascimento de todos os passageiros; e preenchimento do contrato de aquisição de serviços. A Vaga só é reservada mediante pagamento integral;",
      "•	Valor do pacote sujeito a reajuste sem prévio aviso e mediante disponibilidade de vagas no ato da reserva;",
      "•	Os voos poderão sofrer alterações por parte da cia aérea (bloqueio aéreo). A tarifa aérea não permite reembolso.",
      "DOCUMENTOS PARA VIAGEM: Carteira de identidade (RG – recomenda-se emissão menos de 10 anos) em bom estado, CNH – ORIGINAL ou passaporte. Para crianças é aceito a certidão de nascimento    ORIGINAL, quando viajando com um dos pais. *Menores de idade a partir de 12 anos deverão apresentar o RG válido; ",
      "Menores viajando com outras pessoas que não sejam os pais deverá apresentar autorização dos dois pais. Documentação, autorizações e vacinas exigidas para o destino é de responsabilidade do passageiro.",
    ],
    image: CarnavalPgl,
    imageH: CarnavalPglH,
    lamina: CarnavalPglL,
    pdf: CarnavalPglPdf,
    dayByDay: [],
  },

  {
    type: SlideType.nacional,
    isBloqueio: true,
    title: "Promoção Fortaleza",
    period: "06 noites",
    initialDate: "21/11/2024",
    finalDate: "27/11/2024",
    parcelValue: 144.0,
    qtdParcel: 12,
    entry: 0.0,
    value: 1728.0,
    include: [
      "Passagem Aérea Vitoria x Fortaleza x Vitoria com a Latam com direito a 01 mala despachada de até 23kg;",
      "06 Noites de Hospedagem conforme escolha com café da manhã;",
      "Traslados aeroporto x Hotel x aeroporto - Regular;",
      "City Tour em Fortaleza - Panorâmico + Ida a Cumbuco - Regular;",
      "Seguro viagem.",
      "Pacote não inclui: Taxa de Embarque, Gorjetas, despesas pessoais, alimentação, bebidas e serviços não mencionados acima.",
    ],
    description: [
      "Hotel Algarve - Categoria Econômico",
      "Valor por Pessoa em apto Duplo Standard (Cama de Casal) - R$ 1.630,00 + Txs de Embarque - R$ 98,00",
      "12x de R$ 144,00 - SEM ENTRADA",
      " ",

      "Hotel Praia Centro - Turístico",
      "Valor por Pessoa em apto Duplo - R$ 2.188,00 + Txs de Embarque - R$ 98,00",
      "12x de R$ 190,50 - SEM ENTRADA",
      " ",

      "Hotel Praiano - Superior",
      "Valor por Pessoa em apto Duplo - R$ 2.872,00 + Txs de Embarque - R$ 98,00",
      "12x de R$ 247,50 - SEM ENTRADA",
      " ",

      "* Para valores e disponibilidade de outros hotéis, favor consulte com seu agente de viagens.",
      "* Crianças até 3 anos favor verifique o valor com seu agente. As crianças a partir de 4 anos já pagam valor normal.",
    ],
    policy: [
      "•	Para a pré-reserva, obrigatório envio de nome completo, RG, CPF e data de nascimento de todos os passageiros e assinatura do contrato de aquisição de serviços. A Vaga só é reservada mediante pagamento integral; ",
      "•	Valor do pacote sujeito a reajuste sem prévio aviso e mediante disponibilidade de vagas no ato da reserva;",
      "•	Os voos poderão sofrer alterações por parte da cia aérea (bloqueio aéreo). A tarifa aérea não permite reembolso.",
      "DOCUMENTOS PARA VIAGEM: Carteira de identidade (RG – recomenda-se emissão menos de 10 anos) em bom estado, CNH – ORIGINAL ou passaporte válido. Para crianças (0 a 11 anos) é aceito a certidão de nascimento  ORIGINAL, quando viajando com um dos pais. *Menores de idade a partir de 12 anos deverão apresentar o RG válido ou passaporte válido; *Menores viajando com outras pessoas que não sejam os pais deverá apresentar autorização dos dois pais. A ausência de documentos no embarque o passageiro não embarca causando No-show, sem possibilidade de reembolso ou crédito do valor pago. Documentação, autorizações e vacinas exigidas para o destino é de responsabilidade do passageiro.",
      "FORMAS DE PAGAMENTO:",
      "- Entrada + taxa de embarque (Pix, Transferência ou depósito) + saldo em até 9 vezes (link) ou 12 vezes (máquina) no Cartão sem juros;",
      "- Pagamento à vista 3% desconto;",
    ],
    image: FortalezaPromo,
    imageH: FortalezaPromoH,
    lamina: FortalezaPromoL,
    pdf: FortalezaPromoPdf,
    dayByDay: [],
  },

  {
    type: SlideType.nacional,
    isBloqueio: true,
    title: "Feriado em Morro de São Paulo",
    period: "04 noites",
    initialDate: "24/04/2025",
    finalDate: "28/04/2025",
    parcelValue: 140.0,
    qtdParcel: 12,
    entry: 622.0,
    value: 2302.0,
    include: [
      "Passagem aérea Vitoria x Salvador x Vitoria com a Azul com bagagem de ate 10kg;",
      "04 Noites de Hospedagem conforme escolha com café da manhã;",
      "Traslados aeroporto x Morro x aeroporto - Semi Terrestre;",
      "Seguro viagem",
      "",
      "Pacote não inclui: Taxa de Embarque, Gorjetas, despesas pessoais, alimentação, bebidas e serviços não mencionados acima."
    ],
    description: [
      "VALOR POR PESSOA EM R$, A PARTIR DE:",
      "",
      "- Pousada Perola do Morro em apto Standard com Varanda (com cama de casal)",
      "   Valor por pessoa em apto Duplo -R$ 2.240,00 + 102,00 Txs",
      "   Entrada de R$ 662,00 + Saldo em 12 x de R$ 140,00",
      "",
      "- Pousada Brisa do Caita em apto Standard",
      "   Valor por pessoa em apto Duplo - R$ 2.480,00 + 102,00 Txs",
      "   Entrada - R$ 722,00 + Saldo em 12 x de R$ 155,00",
      "",
      "* para valores e disponibilidade de outros hotéis, favor consulte com seu agente de viagens.",
      "* crianças até 3 anos favor verifique o valor com seu agente. As crianças a partir de 4 anos já pagam valor normal.",
    ],
    policy: [
      "•	Para a pré-reserva, obrigatório envio de nome completo, RG, CPF e data de nascimento de todos os passageiros e assinatura do contrato de aquisição de serviços. A Vaga só é reservada mediante pagamento integral; ",
      "•	Valor do pacote sujeito a reajuste sem prévio aviso e mediante disponibilidade de vagas no ato da reserva;",
      "•	Os voos poderão sofrer alterações por parte da cia aérea (bloqueio aéreo). A tarifa aérea não permite reembolso.",
      "DOCUMENTOS PARA VIAGEM: Carteira de identidade (RG – recomenda-se emissão menos de 10 anos) em bom estado, CNH – ORIGINAL ou passaporte válido. Para crianças (0 a 11 anos) é aceito a certidão de nascimento  ORIGINAL, quando viajando com um dos pais. *Menores de idade a partir de 12 anos deverão apresentar o RG válido ou passaporte válido; *Menores viajando com outras pessoas que não sejam os pais deverá apresentar autorização dos dois pais. A ausência de documentos no embarque o passageiro não embarca causando No-show, sem possibilidade de reembolso ou crédito do valor pago. Documentação, autorizações e vacinas exigidas para o destino é de responsabilidade do passageiro.",
      "FORMAS DE PAGAMENTO:",
      "- Entrada + taxa de embarque (Pix, Transferência ou depósito) + saldo em até 9 vezes (link) ou 12 vezes (máquina) no Cartão sem juros;",
      "- Pagamento à vista 3% desconto;",
    ],
    image: FeriadoMorro,
    imageH: FeriadoMorroH,
    lamina: FeriadoMorroL,
    pdf: FeriadoMorroPdf,
    dayByDay: [],
  },

  {
    type: SlideType.nacional,
    isBloqueio: true,
    title: "Férias de Janeiro em Natal",
    period: "05 noites",
    initialDate: "30/01/2025",
    finalDate: "04/02/2025",
    parcelValue: 149.25,
    qtdParcel: 12,
    entry: 568.0,
    value: 2270.0,
    include: [
      "Passagem aérea com uma bagagem de 10kg e outra 23Kg;",
      "05 Noites de Hospedagem em Natal conforme escolha apto Std  com café da manhã;",
      "Traslados aeroporto x Hotel x aeroporto - Regular;",
      "City Tour em Natal - Panorâmico  + Praia de Camurupim - Regular;",
      "Seguro viagem",
    ],
    description: [
      "Pacote não inclui: Taxa de Embarque, Gorjetas, despesas pessoais, alimentação, bebidas e serviços não mencionados acima.",
      "",
      "Villa Park Hotel ( Econômico):",
      " Valor por pessoa em apto Duplo Std  - R$ 2.270,00 + Txs de Embarque - R$ 92,00  ( Cama de Casal)",
      " Entrada de R$ 568,00  + Saldo em 12 x de R$ 149,50",
      "",
      " Valor por pessoa em apto Triplo Std - R$ 2.182,00  + Txs de Embarque - R$ 92,00  ( Cama de Casal + Solteiro)",
      "Entrada de R$ 546,00  + Saldo em 12 x de R$ 144,00",
      "",
      "Hotel Pontalmar (Turístico Superior)",
      " Valor por pessoa em apto Duplo Luxo  - R$ 3.015,00 + Txs de Embarque - R$ 92,00",
      " Entrada de R$ 755,00   + Saldo em 12 x de R$ 196,00",
      "",
      " Valor por pessoa em apto Triplo Luxo - R$ 2.852,00   + Txs de Embarque - R$ 92,00",
      " Entrada de R$ 712,00  + Saldo em 12 x de R$ 186,00"

    ],
    policy: [
      "Para a pré-reserva, obrigatório envio de nome completo, RG, CPF e data de nascimento de todos os passageiros; e assinatura do contrato de aquisição de serviços. A Vaga só é reservada mediante pagamento integral;",
      "Valor do pacote sujeito a reajuste sem prévio aviso e mediante disponibilidade de vagas no ato da reserva; ",
      "Os voos poderão sofrer alterações por parte da cia aérea (bloqueio aéreo).",
      "Consulte-nos valores e disponibilidade de outros hotéis de acordo com a necessidade do cliente.",
      "DOCUMENTOS PARA VIAGEM: Carteira de identidade (RG – recomenda-se emissão menos de 10 anos) em bom estado ou CNH ORIGINAL. Para crianças é aceito a certidão de nascimento ORIGINAL, quando viajando com um dos pais. * Menores de idade a partir de 12 anos deverão apresentar o RG;",
      "*Menores viajando com outras pessoas que não sejam os pais deverá apresentar autorização dos dois pais. Documentação, autorizações e vacinas exigidas para o destino é de responsabilidade do passageiro.",
      "",
      "FORMA DE PAGAMENTO:",
      "25% de Entrada - (Pix, Transferência ou depósito)",
      "Parcelamento Via Link em até 10 vezes s/juros(link) / Parcelamento Via Cartão de crédito em até 12 vezes maquineta loja",
      "A Vista: 03% de desconto sobre Tarifa",
    ],
    image: NatalJaneiro,
    imageH: NatalJaneiroH,
    lamina: NatalJaneiroL,
    pdf: NatalJaneiroPdf,
    dayByDay: [],
  },

  {
    type: SlideType.nacional,
    isBloqueio: true,
    title: "Férias de Janeiro em Fortaleza",
    period: "07 noites",
    initialDate: "29/01/2025",
    finalDate: "05/02/2025",
    parcelValue: 195.00,
    qtdParcel: 12,
    entry: 749.50,
    value: 2997.50,
    include: [
      "Passagem aérea Vitoria  x Fortaleza x Vitoria  com a Latam com direito a 01 mala despachada de até 23Kg;",
      "07 Noites de Hospedagem em Fortaleza conforme escolha  apto Std  com café da manhã;",
      "Traslados aeroporto x Hotel x aeroporto - Regular;",
      "City Tour em Fortaleza - Panoramico + ida a cumbuco - Regular;",
      "Seguro viagem",
    ],
    description: [
      "Pacote não inclui: Taxa de Embarque, Gorjetas, despesas pessoais, alimentação, bebidas e serviços não mencionados acima.",
      "",
      "Hotel Villa Smart  - Economico:",
      " Valor por pessoa em apto Duplo Std  - R$ 2.997,50 + Txs de Embarque - R$ 92,00  ( Cama de Casal)",
      " Entrada de R$ 749,50 + Saldo em 12 x de R$ 195,00",
      "",
      " Valor por pessoa em apto Triplo Std - R$ 2.790,00 + Txs de Embarque - R$ 92,00  ( Cama de Casal + Solteiro)",
      " Entrada de R$ 698,00 + Saldo em 12 x de R$ 182,00",
      "",

      "Hotel Ibis Fortaleza Praia de Iracema - Turistico",
      " Valor por pessoa em apto Duplo Std  - R$ 3.244,00   + Txs de Embarque - R$ 92,00   ( Cama de Casal)",
      " Entrada de R$ 816,00  + Saldo em 12 x de R$ 210,00",
      "",
      " Valor por pessoa em apto Triplo Std - R$ 3.107,00 + Txs de Embarque - R$ 92,00   ( Cama de Casal + Solteiro)",
      " Entrada de R$ 775,00  + Saldo em 12 x de R$ 202,00",
      "",

      "Hotel Beira Mar - Superior",
      " Valor por pessoa em apto Duplo Std  - R$ 4.222,00  + Txs de Embarque - R$ 92,00 ",
      " Entrada de R$ 990,00  + Saldo em 12 x de R$ 277,00",
      "",
      " Valor por pessoa em apto Triplo Std - R$ 3.747,00  + Txs de Embarque - R$ 92,00",
      " Entrada de R$ 935,00  + Saldo em 12 x de R$ 242,00",

    ],
    policy: [
      "Para a pré-reserva, obrigatório envio de nome completo, RG, CPF e data de nascimento de todos os passageiros; e assinatura do contrato de aquisição de serviços. A Vaga só é reservada mediante pagamento integral;",
      "Valor do pacote sujeito a reajuste sem prévio aviso e mediante disponibilidade de vagas no ato da reserva; ",
      "Os voos poderão sofrer alterações por parte da cia aérea (bloqueio aéreo).",
      "Consulte-nos valores e disponibilidade de outros hotéis de acordo com a necessidade do cliente.",
      "DOCUMENTOS PARA VIAGEM: Carteira de identidade (RG – recomenda-se emissão menos de 10 anos) em bom estado ou CNH ORIGINAL. Para crianças é aceito a certidão de nascimento ORIGINAL, quando viajando com um dos pais. * Menores de idade a partir de 12 anos deverão apresentar o RG;",
      "*Menores viajando com outras pessoas que não sejam os pais deverá apresentar autorização dos dois pais. Documentação, autorizações e vacinas exigidas para o destino é de responsabilidade do passageiro.",
      "",
      "FORMA DE PAGAMENTO:",
      "25% de Entrada - (Pix, Transferência ou depósito)",
      "Parcelamento Via Link em até 10 vezes s/juros(link) / Parcelamento Via Cartão de crédito em até 12 vezes maquineta loja",
      "A Vista: 03% de desconto sobre Tarifa",
    ],
    image: FortalezaJaneiro,
    imageH: FortalezaJaneiroH,
    lamina: FortalezaJaneiroL,
    pdf: FortalezaJaneiroPdf,
    dayByDay: [],
  },

  {
    type: SlideType.nacional,
    isBloqueio: true,
    title: "Férias de Janeiro em Maceió",
    period: "5 noites",
    initialDate: "30/01/2025",
    finalDate: "04/02/2025",
    parcelValue: 152.0,
    qtdParcel: 12,
    entry: 576.0,
    value: 2400.0,
    include: [
      "Passagem aérea com uma bagagem de 10kg e outra 23Kg ;",
      "05 Noites de Hospedagem conforme escolha com café;",
      "Traslados aeroporto x Hotel x aeroporto - Regular;",
      "City Tour Maceió - Panorâmico + Passeio Litoral Sul com Praia do Frances - Regular ;",
      "Seguro Viagem",
    ],
    description: [
      "Pacote não inclui: Taxa de Embarque, Gorjetas, despesas pessoais, alimentação, bebidas e serviços não mencionados acima. Para valores e disponibilidade de outros hotéis, favor consulte com seu agente de viagens.",
      " ",
      "ACQUA INN HOTEL - ECONÔMICO",
      " Valor por pessoa em apto Duplo - R$ 2.400,00 + Txs de Embarque - R$ 92,00",
      " Entrada de R$ 576,00 + Saldo em 12 x de R$ 152,00",

      " Valor para apto Single - R$ 3.500,00 + Txs de Embarque - R$ 92,00",
      " Entrada de R$ 860,00 + Saldo em 12 x de R$ 220,00",

      " ",
      "SLIM PAJUÇARA BY TROPICALIS - TURÍSTICO",
      " Valor por pessoa em apto Duplo - R$ 2.560,00 + Txs de Embarque - R$ 92,00",
      " Entrada de R$ 640,00 + Saldo em 12 x de R$ 168,00",

      " Valor para apto Single - R$ 3.680,00 + Txs de Embarque - R$ 92,00",
      " Entrada de R$ 920,00 + Saldo em 12 x de R$ 238,00",
      
      " Valor por pessoa em apto Triplo - R$ 2.360,00 + Txs de Embarque - R$ 92,00",
      " Entrada de R$ 590,00 + Saldo em 12 x de R$ 155,50",
      " ",

      "* para valores e disponibilidade de outros hotéis, favor consulte com seu agente de viagens.",
      "* crianças até 3 anos favor verifique o valor com seu agente. As crianças a partir de 4 anos já pagam valor normal",
    ],

    policy: [
      "FORMAS DE PAGAMENTO: ",
      "- Entrada + taxa de embarque (Pix, Transferência ou depósito) + saldo em até 9 vezes (link) ou 12 vezes (maquina) no Cartão s/ juros; Pagamento à vista 3% desconto;",
      "Valor por pessoa em apto Duplo - R$ 3.956,00 + Txs de Embarque - R$ 92,00 ",
      "Entrada de R$ 988,00 + Saldo em ate 12 x sem Juros nos cartões - Via maquineta ou em ate 10 x via link - R$ 255,00 ",
      "Condições Gerais:",
      "•	Para a pré-reserva, obrigatório envio de nome completo, RG, CPF e data de nascimento de todos os passageiros; e preenchimento do contrato de aquisição de serviços. A Vaga só é reservada mediante pagamento integral;",
      "•	Valor do pacote sujeito a reajuste sem prévio aviso e mediante disponibilidade de vagas no ato da reserva;",
      "•	Os voos poderão sofrer alterações por parte da cia aérea (bloqueio aéreo). A tarifa aérea não permite reembolso.",
      "DOCUMENTOS PARA VIAGEM: Carteira de identidade (RG – recomenda-se emissão menos de 10 anos) em bom estado, CNH – ORIGINAL ou passaporte. Para crianças é aceito a certidão de nascimento    ORIGINAL, quando viajando com um dos pais. *Menores de idade a partir de 12 anos deverão apresentar o RG válido; ",
      "Menores viajando com outras pessoas que não sejam os pais deverá apresentar autorização dos dois pais. Documentação, autorizações e vacinas exigidas para o destino é de responsabilidade do passageiro.",
    ],
    image: MaceioJaneiro,
    imageH: MaceioJaneiroH,
    lamina: MaceioJaneiroL,
    pdf: MaceioJaneiroPdf,
    dayByDay: [],
  },

  {
    type: SlideType.nacional,
    isBloqueio: true,
    title: "Férias de Janeiro em Salvador",
    period: "4 noites",
    initialDate: "31/01/2025",
    finalDate: "04/02/2025",
    parcelValue: 122.0,
    qtdParcel: 12,
    entry: 450.0,
    value: 1914.0,
    include: [
      "Passagem Aérea Vitoria x Salvador x Vitoria com Latam com direito a 01 mala de ate 23 kg;",
      "04 Noites de Hospedagem conforme escolha com café ;",
      "Traslados aeroporto x Hotel x aeroporto - Regular;",
      "City Tour Histórico em Salvador – Regular;",
      "Seguro Viagem",
    ],
    description: [
      "Pacote não inclui: Taxa de Embarque, Gorjetas, despesas pessoais, alimentação, bebidas e serviços não mencionados acima. Para valores e disponibilidade de outros hotéis, favor consulte com seu agente de viagens.",
      " ",
      "HOTEL  VERDEMAR - ECONÔMICO",
      " Valor por pessoa em apto Duplo - R$ 1.820,00 + Txs de Embarque - R$ 94,00",
      " Entrada de R$ 450,00 + Saldo em 12 x de R$ 122,00",

      " Valor para apto Single - R$ 2.532,00 + Txs de Embarque - R$ 94,00",
      " Entrada de R$ 634,00 + Saldo em 12 x de R$ 166,00",

      " Valor para apto Triplo - R$ 1.682,00 + Txs de Embarque - R$ 94,00",
      " Entrada de R$ 420,00 + Saldo em 12 x de R$ 113,00",

      " ",
      "VILA GALÉ SALVADOR - SUPERIOR",

      " Valor por pessoa em apto Duplo - R$ 2.145,00 + Txs de Embarque - R$ 94,00",
      " Entrada de R$ 535,00 + Saldo em 12 x de R$ 142,00",

      " Valor para apto Single - R$ 3.090,00 + Txs de Embarque - R$ 94,00",
      " Entrada de R$ 722,00 + Saldo em 12 x de R$ 201,00",
      
      " Valor por pessoa em apto Triplo - R$ 2.064,00 + Txs de Embarque - R$ 94,00",
      " Entrada de R$ 514,00 + Saldo em 12 x de R$ 137,00",
      " ",

      "* para valores e disponibilidade de outros hotéis, favor consulte com seu agente de viagens.",
      "* crianças até 3 anos favor verifique o valor com seu agente. As crianças a partir de 4 anos já pagam valor normal",
    ],

    policy: [
      "FORMAS DE PAGAMENTO: ",
      "- Entrada + taxa de embarque (Pix, Transferência ou depósito) + saldo em até 9 vezes (link) ou 12 vezes (maquina) no Cartão s/ juros; Pagamento à vista 3% desconto;",
      "Valor por pessoa em apto Duplo - R$ 3.956,00 + Txs de Embarque - R$ 92,00 ",
      "Entrada de R$ 988,00 + Saldo em ate 12 x sem Juros nos cartões - Via maquineta ou em ate 10 x via link - R$ 255,00 ",
      "Condições Gerais:",
      "•	Para a pré-reserva, obrigatório envio de nome completo, RG, CPF e data de nascimento de todos os passageiros; e preenchimento do contrato de aquisição de serviços. A Vaga só é reservada mediante pagamento integral;",
      "•	Valor do pacote sujeito a reajuste sem prévio aviso e mediante disponibilidade de vagas no ato da reserva;",
      "•	Os voos poderão sofrer alterações por parte da cia aérea (bloqueio aéreo). A tarifa aérea não permite reembolso.",
      "DOCUMENTOS PARA VIAGEM: Carteira de identidade (RG – recomenda-se emissão menos de 10 anos) em bom estado, CNH – ORIGINAL ou passaporte. Para crianças é aceito a certidão de nascimento    ORIGINAL, quando viajando com um dos pais. *Menores de idade a partir de 12 anos deverão apresentar o RG válido; ",
      "Menores viajando com outras pessoas que não sejam os pais deverá apresentar autorização dos dois pais. Documentação, autorizações e vacinas exigidas para o destino é de responsabilidade do passageiro.",
    ],
    image: SalvadorJaneiro,
    imageH: SalvadorJaneiroH,
    lamina: SalvadorJaneiroL,
    pdf: SalvadorJaneiroPdf,
    dayByDay: [],
  },

  {
    type: SlideType.nacional,
    isBloqueio: true,
    title: "Férias de Janeiro em Morro de São Paulo",
    period: "06 noites",
    initialDate: "19/01/2025",
    finalDate: "25/01/2025",
    parcelValue: 158.0,
    qtdParcel: 12,
    entry: 690.0,
    value: 2586.0,
    include: [
      "Passagem aérea Vitoria x Salvador x Vitoria Com a Gol Com bagagem de até 23 Kg;",
      "06 Noites de Hospedagem com café da manhã em MORRO no hotel escolhido;",
      "Traslados aeroporto x Morro x aeroporto - Semi Terrestre;",
      "Seguro viagem",
      "",
      "Pacote não inclui: Taxa de Embarque, Gorjetas, despesas pessoais, alimentação, bebidas e serviços não mencionados acima."
    ],
    description: [
      "VALOR POR PESSOA EM R$, A PARTIR DE:",
      "",
      "- Pousada Xerife em apto Standard",
      "   Valor por pessoa em apto Duplo -R$ 2.590,00 + 90,00 Txs",
      "   Entrada de R$ 670,00 + Saldo em 12 x de R$ 167,50",
      "",
      "- Hotel Morro da Saudade - 2ª Praia",
      "   Valor por pessoa em apto Duplo - R$ 2.790,00 + 90,00 Txs",
      "   Entrada - R$ 720,00 + Saldo em 12 x de R$ 180,00",
      "",
      "* para valores e disponibilidade de outros hotéis, favor consulte com seu agente de viagens.",
      "* crianças até 3 anos favor verifique o valor com seu agente. As crianças a partir de 4 anos já pagam valor normal.",
    ],
    policy: [
      "•	Para a pré-reserva, obrigatório envio de nome completo, RG, CPF e data de nascimento de todos os passageiros e assinatura do contrato de aquisição de serviços. A Vaga só é reservada mediante pagamento integral; ",
      "•	Valor do pacote sujeito a reajuste sem prévio aviso e mediante disponibilidade de vagas no ato da reserva;",
      "•	Os voos poderão sofrer alterações por parte da cia aérea (bloqueio aéreo). A tarifa aérea não permite reembolso.",
      "DOCUMENTOS PARA VIAGEM: Carteira de identidade (RG – recomenda-se emissão menos de 10 anos) em bom estado, CNH – ORIGINAL ou passaporte válido. Para crianças (0 a 11 anos) é aceito a certidão de nascimento  ORIGINAL, quando viajando com um dos pais. *Menores de idade a partir de 12 anos deverão apresentar o RG válido ou passaporte válido; *Menores viajando com outras pessoas que não sejam os pais deverá apresentar autorização dos dois pais. A ausência de documentos no embarque o passageiro não embarca causando No-show, sem possibilidade de reembolso ou crédito do valor pago. Documentação, autorizações e vacinas exigidas para o destino é de responsabilidade do passageiro.",
      "FORMAS DE PAGAMENTO:",
      "- Entrada + taxa de embarque (Pix, Transferência ou depósito) + saldo em até 9 vezes (link) ou 12 vezes (máquina) no Cartão sem juros;",
      "- Pagamento à vista 3% desconto;",
    ],
    image: MorroJaneiro,
    imageH: MorroJaneiroH,
    lamina: MorroJaneiroL,
    pdf: MorroJaneiroPdf,
    dayByDay: [],
  },

  {
    type: SlideType.internacional,
    title: "Férias de Julho no Chile",
    period: "5 noites",
    initialDate: "17/07/2025",
    finalDate: "22/07/2025",
    parcelValue: 86.0,
    qtdParcel: 9,
    entry: 366.0,
    value: 1140.0,
    include: [
      "Passagem Aérea ida e volta com franquia de bagagem de 10Kg;",
      "05 Noites de Hospedagem com café da manhã conforme opções abaixo;",
      "Transfer in/out - Aeroporto x Hotel x Aeroporto;",
      "Seguro de viagem.",
    ],
    description: [
      "Não está incluso no pacote: Gorjetas, Entradas não mencionadas no roteiro, Refeições não mencionadas no roteiro ",
      "",
      "Horário de voo:",
      "17/07/25 - LA 4537 - Vitoria x Guarulhos  - 04:25 / 06:05",
      "17/07/25 - LA 0605 - Guarulhos x Santiago - 08:35 / 11:54",
      "22/07/25 - LA 0750 - Santiago x Guarulhos - 13:47 / 18:45",
      "22/07/25 - LA 3336 - Guarulhos x Vitoria  - 23:15 / 00:45",
      "",
      "Valor por pessoa:",
      "01º OPÇÃO - MR.APART PROVIDENCIA 3* - SUITE",
      "APTO DUPLO - USD 1.090,00 + USD 50,00 Tx de Embarque",
      "Forma de Pagamento: Entrada USD 366,00 + 09 X de USD 86,00",
      "APTO SINGLE - USD 1.290,00 + USD 50,00 Tx de Embarque",
      "Forma de Pagamento: Entrada USD 404,00 + 09 X de USD 104,00",
      "APTO TRIPLO - USD 1.062,00 + USD 50,00 Tx de Embarque",
      "Forma de Pagamento: Entrada USD 356,00 + 09 X de USD 84,00",
      "",
      "02º OPÇÃO - HOTEL TORREMAYOR LYON PROVIDENCIA 4* - SUITE",
      "APTO DUPLO - USD 1.099,00 + USD 50,00 Tx de Embarque",
      "Forma de Pagamento: Entrada USD 285,00 + 09 X de USD 96,00",
      "APTO SINGLE - USD 1.360,00 + USD 50,00 Tx de Embarque",
      "Forma de Pagamento: Entrada USD 357,00 + 09 X de USD 117,00",
      "APTO TRIPLO - USD 1.075,00 + USD 50,00 Tx de Embarque",
      "Forma de Pagamento: Entrada USD 279,00 + 09 X de USD 94,00",
     
    ],
    policy: [
      "•	Para a pré-reserva, obrigatório envio de nome completo, RG, CPF e data de nascimento de todos os passageiros e assinatura do contrato de aquisição de serviços. A Vaga só é reservada mediante pagamento integral; ",
      "•	Valor do pacote sujeito a reajuste sem prévio aviso e mediante disponibilidade de vagas no ato da reserva;",
      "•	Os voos poderão sofrer alterações por parte da cia aérea (bloqueio aéreo). A tarifa aérea não permite reembolso.",
      "DOCUMENTOS PARA VIAGEM: Carteira de identidade (RG – recomenda-se emissão menos de 10 anos) em bom estado, CNH – ORIGINAL ou passaporte válido. Para crianças (0 a 11 anos) é aceito a certidão de nascimento  ORIGINAL, quando viajando com um dos pais. *Menores de idade a partir de 12 anos deverão apresentar o RG válido ou passaporte válido; *Menores viajando com outras pessoas que não sejam os pais deverá apresentar autorização dos dois pais. A ausência de documentos no embarque o passageiro não embarca causando No-show, sem possibilidade de reembolso ou crédito do valor pago. Documentação, autorizações e vacinas exigidas para o destino é de responsabilidade do passageiro.",
      "FORMAS DE PAGAMENTO:",
      "- Entrada + taxa de embarque (Pix, Transferência ou depósito) + saldo em até 9 vezes (link) ou 12 vezes (máquina) no Cartão sem juros;",
      "- Pagamento à vista 3% desconto;",
      "",
      "Observação:",
      "Adicional de bagagem de 23kg para despacho USD 96,00 por pessoa",
      "Valores por pessoa em dólar onde o mesmo deverá ser convertido em real no data do cambio do dia",
      "bloqueio aéreo, vagas limitadas sujeito a disponibilidade no ato da reserva",
    ],
    image: ChileJulho,
    imageH: ChileJulhoH,
    lamina: ChileJulhoL,
    pdf: ChileJulhoPdf,
    dayByDay: [
      {
        day: "OPCIONAL - CITY TOUR SANTIAGO DE CHILE (USD 38,00 por pessoa):",
        description: [
          "Um passeio pelos setores mais importantes de Santiago, passando pela cidade velha, com paradas no Palácio de La Moneda, Plaza de Armas e, de forma panorâmica, por outros pontos da cidade, e depois recorrer ao mais importante distrito boêmio de a capital: Bellavista; e continuamos com os setores residenciais e parques do município de Providencia, também temos uma parada em uma área verde da cidade e o passeio termina no Mercado Central para almoçar nesse lugar emblemático e se você não tiver um tour de vinhedos a tarde é gratuita para continuar andando pela cidade.",
        ],
      },
      {
        day: "OPCIONAL - VISITE ESTAÇÃO DE SKI FARELLONES, EL COLORADO Y VALLE NEVADO (USD 59,00 por pessoa):",
        description: [
          "Visitaremos a Cordilheira dos Andes, o maior complexo montanhoso da América, subiremos suas mais de 50 curvas de 180 graus, margeando o Rio Mapocho, com paradas nas famosas estações de esqui, incluindo Valle Nevado, com seus hotéis imponentes. alta montanha. Nesta caminhada panorâmica faremos algumas paradas para as mais belas fotos e tempo livre para o almoço na vila de Farellones, no alto da serra.",
        ],
      },
      {
        day: "OPCIONAL - TOUR VIÑA DEL MAR E VALPARAISO (USD 60,00 por pessoa):",
        description: [
          "Um passeio pela quinta região do Chile e suas duas cidades mais emblemáticas. Um pela sua diversão e o outro pela sua tradição. Enquanto Valparaíso, cidade portuária, é caracterizada por suas colinas altas com vistas panorâmicas do Oceano Pacífico e edifícios ancestrais que o levou a ser declarado Patrimônio da Humanidade. Viña del Mar chama a atenção por seus jardins, seu modernismo e sua vida noturna. Esta viagem pode incluir um tour de viagens, se as condições climáticas, trânsito e horários permitirem.",
        ],
      },
      {
        day: "OPCIONAL - VINICULA VINHEDO CONCHA Y TORO PREMIUM (USD 72,00 por pessoa):",
        description: [
          "Um passeio por um dos mais prestigiados vinhedos da região central do Chile e internacionalmente reconhecido. Vamos aprender sobre os seus segredos escondidos, as suas histórias e lendas, bem como oferecer uma degustação dos seus melhores vinhos no local onde são produzidos, com um ambiente típico das vinhas e a antiga residência dos seus proprietários.",
        ],
      },
      {
        day: "OPCIONAL - PORTILLO & LAGUNA DEL INCA PREMIUM - COM ALMOÇO (USD 115,00 por pessoa):",
        description: [
          "Venha desfrutar conosco de um dia incrível, combinando em uma divertida visita guiada conhecendo a história, geografia, fauna e flora da imponente Cordilheira dos Andes. Visitaremos um dos primeiros centros de esqui da América Latina, Portillo, e sua famosa Laguna del Inca, onde o espelho d'água se destaca com o contraste das montanhas. A imponente serra nos dará paisagens impressionantes que ficarão gravadas em nossa retina! Chegaremos à fronteira entre Argentina e Chile, conheceremos o reconhecido 'Caracol', imponente de onde o olhamos! Antes de retornar a Santiago faremos uma parada para desfrutar de um delicioso almoço.",
        ],
      },
    ],
  },
  
];
