import Container from "../../../components/Container";
import { kekData } from "../../../mocks/kekData";
import { Section } from "./style";
import Family from "../../../assets/images/family.png";
import ContactLink from "../../../components/ContactLink";

export default function Contact() {
  return (
    <Container>
      <Section>
        <div>
          <p>Precisa de ajuda para decidir sua próxima experiência?</p>
          <p>Fale conosco!</p>
          <ContactLink title="entre em contato" link={kekData.whatsapp} blank />
        </div>
        <img src={Family} alt="happy family travel" />
      </Section>
    </Container>
  );
}
