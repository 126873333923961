import { TestimoniesProps } from "../types/testimonies";
import Liz from "../assets/images/testimony/liz.png";
import SB from "../assets/images/testimony/SB.png";
import Bravim from "../assets/images/testimony/bravim.png";
import Adutur from "../assets/images/testimony/adutur.png";
import Ilza from "../assets/images/testimony/ilza.png";
import Rafaela from "../assets/images/testimony/rafaela.png";
import LifeGold from "../assets/images/testimony/LifeGold.png";
import Alex from "../assets/images/testimony/Alex.png";
import Choise from "../assets/images/testimony/choise.png";
import Ecotour from "../assets/images/testimony/ecotour.png";
import Elma from "../assets/images/testimony/elma.png";
import Bufon from "../assets/images/testimony/bufon.png";
import Senhoratur from "../assets/images/testimony/Senhoratour.png";
import Ana from "../assets/images/testimony/ana.png";
import Mtur from "../assets/images/testimony/mtur.png";
import Cabetour from "../assets/images/testimony/cabetour.png";
import Diely from "../assets/images/testimony/diely.png";
import Fabio from "../assets/images/testimony/fabio.png";
import MrTurismo from "../assets/images/testimony/mrturismo.png";
import Anita from "../assets/images/testimony/anita.png";
import FavioBarros from "../assets/images/testimony/fabiobarros.png";
import Viajandocomje from "../assets/images/testimony/Viajandocomje.png";
import Happyday from "../assets/images/testimony/Happyday.png";
import Deonina from "../assets/images/testimony/Deonina.png";
import Unitour from "../assets/images/testimony/Unitour.png";
import Trechos from "../assets/images/testimony/trechos.png";

export const testimonies: TestimoniesProps[] = [
  {
    image: Liz,
    name: "Lizane Holz",
    agency: "Via Mundo Travel",
    local: "Vitória - ES",
    whatsapp: "(27) 99740-0072",
    email: "reservas@voudeviamundo.com.br",
  },
  {
    image: SB,
    name: "Leninha Folly",
    agency: "SB Turismo / Leninha Tour",
    local: "Serra - ES",
    whatsapp: "(27) 99813-9429",
    email: "viagensfolly@gmail.com ",
  },
  {
    image: Bravim,
    name: "Antonio Bravim",
    agency: "Bravim Turismo",
    local: "Vitória - ES",
    whatsapp: "(27) 99689-3866",
    email: "bravimturismo@hotmail.com",
  },
  {
    image: Adutur,
    name: "Sonia Valle",
    agency: "Adutur viagens e turismo",
    local: "Guarapari - ES",
    whatsapp: "(27) 99992-2627",
    email: "contato@adutur.com.br",
  },
  {
    image: Ilza,
    name: "Ilza Darc",
    agency: "Ilza D'arc Destinos",
    local: "Jaguaré - ES",
    whatsapp: "(27) 98848-8402",
    email: "ilzadarclima@gmail.com",
  },
  {
    image: Rafaela,
    name: "Rafaela Almeida",
    agency: "Raphaela Almeida Viagens",
    local: "Vila Velha - ES",
    whatsapp: "(27) 99998-5111",
    email: "raphaelamca@gmail.com ",
  },
  {
    image: LifeGold,
    name: "Iliamara Cardoso",
    agency: "Life Gold turismo",
    local: "Vitória - ES",
    whatsapp: "(27) 98827-2021",
    email: "lia@lifeturismo.tur.br",
  },
  {
    image: Alex,
    name: "Alex Trindade",
    agency: "Alex Tour",
    local: "Vila Velha - ES",
    whatsapp: "(27) 99754-1508 ",
    email: "alex-tour@hotmail.com ",
  },
  {
    image: Choise,
    name: "Lourdes",
    agency: "Choice Tour viagens e Turismo",
    local: "Vitória - ES",
    whatsapp: "(27) 99971-3784",
    email: "lourdes@choicetour.com.br",
  },
  {
    image: Ecotour,
    name: "Ecotour Brasil",
    agency: "Ecotour Brasil",
    local: "Cachoeiro de Itapemirim - ES",
    whatsapp: "(28) 99918-0182",
    email: "ecotour@ecotourbrasil.com.br",
  },
  {
    image: Elma,
    name: "Elma Cristina",
    agency: "Elma viagens e turismo",
    local: "Guarapari - ES",
    whatsapp: "(27) 99866-4150",
    email: "elma.cristina@hotmail.com",
  },
  {
    image: Bufon,
    name: "Jocimar Bufon",
    agency: "Bufon Turismo",
    local: "Castelo - ES",
    whatsapp: "(28) 99906-2299",
    email: "jocimar.bufon@gmail.comr",
  },
  {
    image: Senhoratur,
    name: "Mª Senhora Leão",
    agency: "Senhoratour",
    local: "Vitória - ES",
    whatsapp: "(27) 99996-6874",
    email: "msoaleao@hotmail.com",
  },
  {
    image: Ana,
    name: "Ana Viagens",
    agency: "Ana Viagens",
    local: "Vitória - ES",
    whatsapp: "(27) 99238-5413",
    email: "anaviagensoficial@gmail.com ",
  },
  {
    image: Mtur,
    name: "Marcos Cadorini",
    agency: "Mtur Viagens",
    local: "Vitória - ES",
    whatsapp: "(27) 99915-9541",
    email: "marcosmturismo@gmail.com",
  },
  {
    image: Cabetour,
    name: "Cabetour Viagens",
    agency: "Cabetour Viagens",
    local: "Itapemirim - ES",
    whatsapp: "(27) 98803-4236",
    email: "cabetour@gmail.com",
  },
  {
    image: Diely,
    name: "Lorena Miranda",
    agency: "Diely e Lorena Viagens",
    local: "Cariacica - ES",
    whatsapp: "(27) 99834-1443",
    email: "dielyelorenaviagens@hotmail.com",
  },
  {
    image: Fabio,
    name: "Fabio Costa",
    agency: "Costa Turismo",
    local: "Colatina - ES",
    whatsapp: "(27) 99664-1982",
    email: "fabiocosta@costtaturismo.com.br",
  },
  {
    image: MrTurismo,
    name: "Malerne Ragazzi",
    agency: "Mr Turismo",
    local: "Vila Velha - ES",
    whatsapp: "(27) 99625-4068",
    email: "nenaragazzi@hotmail.com",
  },
  {
    image: Anita,
    name: "Anita Poloni",
    agency: "Anita Viagens",
    local: "Vitória - ES",
    whatsapp: "(27) 99952-3449",
    email: "anitapoloni@hotmail.com",
  },
  {
    image: FavioBarros,
    name: "Fabio Barros",
    agency: "Jornadas Turísticas",
    local: "Vitória - ES",
    whatsapp: "(27) 98804-2460",
    email: "fabiob@bol.com.br",
  },
  {
    image: Viajandocomje,
    name: "Jeane Oliveira",
    agency: "Viajandocomje",
    local: "Vitória - ES",
    whatsapp: "(27) 99987-4501",
    email: "viajandocomjeane@gmail.com",
  },
  {
    image: Happyday,
    name: "Alessandra Ferreira",
    agency: "Happyday Viagens",
    local: "Aracruz - ES",
    whatsapp: "(27) 99637-9758",
    email: "happydayviagens57@gmail.com",
  },
  {
    image: Deonina,
    name: "Deonina do Rosario",
    agency: "Deonina Viagens e Turismo",
    local: "Vitória - ES",
    whatsapp: "(27) 99981-0026",
    email: "deoninaviagens@hotmail.com",
  },
  {
    image: Unitour,
    name: "Angela Pretti",
    agency: "Unitour Viagens e Turismo",
    local: "Colatina - ES",
    whatsapp: "(27) 99274-0145",
    email: "unitour@unitour.com.br",
  },
  {
    image: Trechos,
    name: "Tarcio Soares",
    agency: "Trechos Tour",
    local: "Vitória - ES",
    whatsapp: "(27) 99961-3007 ",
    email: "trechostour1@gmail.com",
  },
];
