import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useRef, useCallback, useEffect } from "react";
import Container from "../../../components/Container";
import { Dot, Dots, EmptyPackages, Section, Title } from "./style";
import { useState } from "react";
import Slider from "react-slick";
import Slide from "./slide";
import { SlideType, packages } from "../../../mocks/packages";
import { SectionProps } from "../../../types/section";
import TabsSelection from "../../../components/TabsSelection";
import { formatDateToISO } from "../../../utils/format";

export default function Packages({ referance }: SectionProps) {
  const [packType, setPackType] = useState<number>(SlideType.nacional);

  const sliderRef = useRef<any>(null);

  const changePackType = useCallback(
    (packNumber: number) => {
      sliderRef?.current.slickGoTo(0, true);
      if (packages.filter((pack) => pack.type === packNumber).length >= 3) {
        setPackType(packNumber);
        return;
      }
      setTimeout(() => {
        setPackType(packNumber);
      }, 1);
    },
    [packType]
  );

  const handlePackType = () => {
    return packages.filter(
      (pack) =>
        pack.type === packType &&
        new Date(formatDateToISO(pack.initialDate)) >= new Date()
    );
  };

  const settings = {
    initialSlide: 0,
    dots: handlePackType().length > 0,
    infinite: handlePackType().length > 3,
    autoplay: handlePackType().length > 3,
    draggable: handlePackType().length > 3,
    slidesToScroll: 1,
    arrows: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          arrows: false,
        },
      },
      {
        breakpoint: 950,
        settings: {
          arrows: false,
          slidesToShow: 2,
          dots: handlePackType().length > 0,
          infinite: handlePackType().length > 2,
          autoplay: handlePackType().length > 2,
          draggable: handlePackType().length > 2,
          slidesToScroll: handlePackType().length > 2 ? 1 : 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          arrows: false,
          slidesToShow: 1,
          dots: true,
          infinite: handlePackType().length > 1,
          autoplay: true,
          slidesToScroll: 1,
        },
      },
    ],
    appendDots: (dots: any) => (
      <Dots>
        <Dot> {dots} </Dot>
      </Dots>
    ),
  };

  return (
    <Section ref={referance}>
      <Container>
        <Title>NOSSOS PACOTES</Title>
        <TabsSelection
          value={packType}
          setValue={changePackType}
          options={["nacionais", "internacionais"]}
        />
        {handlePackType().length > 0 ? (
          <Slider {...settings} ref={sliderRef}>
            {handlePackType().map((pack, index) => (
              <Slide pack={pack} props={index} key={index} />
            ))}
          </Slider>
        ) : (
          <EmptyPackages>
            {packType >= 0 && "No momento não temos nenhum PACOTE desse tipo"}
          </EmptyPackages>
        )}
      </Container>
    </Section>
  );
}
