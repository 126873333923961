import { styled } from "styled-components";
import { COLORS, FONT_WEIGHT } from "../../theme";

export const Section = styled.p`
  width: 100%;
  text-align: center;
  background-color: ${COLORS.SECONDARY};
  font-weight: ${FONT_WEIGHT.SEMI_BOLD};
  color: ${COLORS.PRIMARY};
  padding: 8px 0;

  a {
    color: ${COLORS.PRIMARY};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
  }

  @media (max-width: 500px) {
    font-size: 14px;
  }
`;
