import { styled } from "styled-components";
import Background from "../../../assets/images/background.png";
import { COLORS, FONT_WEIGHT } from "../../../theme";

type TabProps = {
  active: boolean;
};

export const BackgroundImage = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;

  @media (max-width: 950px) {
    height: 580px;
  }

  @media (max-width: 700px) {
    height: 540px;
  }
`;

export const SearchContainer = styled.div`
  width: 93%;
  margin: 0 auto;

  @media (max-width: 1400px) {
    width: 1200px;
  }

  @media (max-width: 1200px) {
    width: 90%;
  }

  @media (max-width: 950px) {
    margin-top: 80px;
  }
`;

export const SearchBox = styled.div`
  width: 800px;
  background-color: rgba(245, 245, 245, 0.7);
  border-radius: 20px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.4);
  margin-top: 100px;

  @media (max-width: 1400px) {
    width: 50%;
  }

  @media (max-width: 1200px) {
    width: 70%;
  }

  @media (max-width: 950px) {
    width: 100%;
    margin-top: 0;
  }
`;

export const Tabs = styled.div`
  width: 100%;
  border-bottom: 2px solid ${COLORS.WHITE};
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
`;

export const Tab = styled.button<TabProps>`
  all: unset;
  cursor: pointer;
  width: 100%;
  text-align: center;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  background-color: ${({ active }) =>
    active ? COLORS.PRIMARY : "transparent"};
  color: ${({ active }) => (active ? COLORS.WHITE : COLORS.PRIMARY)};

  & p {
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    font-size: 24px;
    padding: 8px;

    @media (max-width: 1400px) {
      font-size: 18px;
    }

    @media (max-width: 700px) {
      font-size: 14px;
    }

    @media (max-width: 500px) {
      font-size: 12px;
      padding: 8px 0;
    }
  }
`;

export const SearchForm = styled.div`
  padding: 48px 24px 60px 24px;
  display: flex;
  flex-direction: column;

  & label {
    font-size: 22px;
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    color: ${COLORS.PRIMARY};

    @media (max-width: 1400px) {
      font-size: 18px;
    }
  }

  & input {
    border-radius: 20px;
    outline: none;
    border: none;
    font-size: 22px;
    padding: 18px;
    margin-top: 12px;

    @media (max-width: 1400px) {
      font-size: 18px;
    }
  }
`;

export const SearchedTrips = styled.div`
  background-color: ${COLORS.WHITE};
  width: calc(800px - 24px - 24px - 24px);
  position: absolute;
  margin-top: 100px;
  margin-left: 12px;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  max-height: 320px;
  overflow-y: auto;

  @media (max-width: 1400px) {
    width: calc((1200px * 0.5) - 24px - 24px - 24px);
    max-height: 280px;
    margin-top: 90px;
  }

  @media (max-width: 1200px) {
    width: calc(90% * 0.65 - 24px - 24px - 24px);
  }

  @media (max-width: 950px) {
    width: calc(90% - 24px - 24px - 24px);
    margin-top: 90px;
  }
`;

export const TripItem = styled.a`
  border-top: 1px solid #d1d1d1;
  padding: 18px;
  font-size: 22px;
  cursor: pointer;
  transition: 0.4s all;
  color: ${COLORS.PRIMARY};
  text-overflow: ellipsis;
  text-decoration: none;

  &:hover {
    transition: 0.4s all;
    background-color: ${COLORS.GRAY};
  }

  @media (max-width: 1400px) {
    font-size: 18px;
  }

  @media (max-width: 950px) {
    padding: 12px;
  }
`;

export const TripEmpty = styled.p`
  border-top: 1px solid #d1d1d1;
  padding: 18px;
  font-size: 22px;
  transition: 0.4s all;
  color: ${COLORS.PRIMARY};
  text-align: center;
`;
