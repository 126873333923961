import { styled } from "styled-components";
import { COLORS, FONT_WEIGHT } from "../../theme";

export const Section = styled.div`
  padding: 64px 0;

  @media (max-width: 1400px) {
    padding: 32px 0;
  }
`;

export const TabContent = styled.div`
  background-color: ${COLORS.GRAY};
  border-radius: 20px;
  p {
    padding: 18px 24px;
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    font-size: 18px;
    display: flex;
    flex-direction: column;

    b {
      font-weight: ${FONT_WEIGHT.BOLD};
      margin-bottom: 4px;
    }

    ul {
      list-style-position: inside;

      li {
        margin: 12px 0;
      }

      :first-child {
        margin-top: 0px;
      }

      :last-child {
        margin-bottom: 0px;
      }
    }

    i {
      font-size: 16px;
      font-weight: ${FONT_WEIGHT.REGULAR};
    }
  }
`;

export const PackageImg = styled.img`
  width: 100%;
  border-radius: 20px;
`;

export const PackageInfo = styled.div`
  width: 85%;
  margin: 0 auto;
  border-radius: 20px;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  margin-top: -112px;
  position: relative;
  z-index: 1;

  @media (max-width: 1400px) {
    margin-top: -96px;
  }

  @media (max-width: 950px) {
    margin-top: -48px;
  }

  @media (max-width: 450px) {
    width: 100%;
    margin-top: 8px;
    box-shadow: none;
  }
`;

export const Flex = styled.div`
  padding: 32px 24px;
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: 30% 34% 30%;

  @media (max-width: 1400px) {
    padding: 18px 24px;
  }

  @media (max-width: 950px) {
    grid-template-columns: 100%;
    gap: 12px;
  }

  h1 {
    font-size: 32px;
    font-weight: ${FONT_WEIGHT.BOLD};
    color: ${COLORS.PRIMARY};
    text-align: center;

    @media (max-width: 1400px) {
      font-size: 28px;
    }
  }
`;

export const Infos = styled.div`
  font-size: 20px;
  color: ${COLORS.PRIMARY};
  text-align: center;
  border-left: 1px solid ${COLORS.PRIMARY};
  border-right: 1px solid ${COLORS.PRIMARY};

  p {
    padding-top: 4px;
  }

  @media (max-width: 1400px) {
    font-size: 18px;
    padding: 8px 0;
  }

  @media (max-width: 950px) {
    border: none;
    border-top: 1px solid ${COLORS.PRIMARY};
    border-bottom: 1px solid ${COLORS.PRIMARY};
  }

  @media (max-width: 450px) {
    border: none;
  }

  b {
    font-weight: ${FONT_WEIGHT.EXTRA_BOLD};

    small {
      font-size: 58px;
      font-weight: ${FONT_WEIGHT.EXTRA_BOLD};

      @media (max-width: 1400px) {
        font-size: 48px;
      }
    }
  }
`;

export const FlexColumns = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 12px;

  span {
    color: ${COLORS.PRIMARY};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    font-size: 24px;

    @media (max-width: 1400px) {
      font-size: 22px;
    }
  }
`;

export const Badges = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0 64px 0;
  gap: 18px;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const Badge = styled.p`
  font-size: 22px;
  padding: 8px 18px;
  font-weight: ${FONT_WEIGHT.SEMI_BOLD};
  background: ${COLORS.SECONDARY};
  color: ${COLORS.PRIMARY};
  border-radius: 20px;
  text-align: center;

  @media (max-width: 1400px) {
    font-size: 18px;
  }

  @media (max-width: 1200px) {
    width: 100%;
    padding: 8px 0;
  }

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const DownloadContent = styled.div`
  display: flex;
  margin-top: 32px;
  margin-bottom: 12px;

  button:first-child {
    margin-right: 12px;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    button:first-child {
      margin-bottom: 14px;
      margin-right: 0;
    }
  }
`;
