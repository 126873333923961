import Slider from "react-slick";
import Container from "../../../components/Container";
import { testimonies } from "../../../mocks/testimonies";
import { Dot, Dots, Section, TestimoniesBox, Title } from "./style";
import Slide from "./slide";
import { SectionProps } from "../../../types/section";

export default function Testimonies({ referance }: SectionProps) {
  const settings = {
    dots: testimonies.length > 3,
    infinite: testimonies.length > 3,
    autoplay: testimonies.length > 3,
    slidesToScroll: testimonies.length > 3 ? 3 : 1,
    arrows: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1040,
        settings: {
          slidesToShow: 2,
          arrows: false,
          dots: testimonies.length > 2,
          infinite: testimonies.length > 2,
          autoplay: testimonies.length > 2,
          slidesToScroll: testimonies.length > 2 ? 1 : 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
          infinite: true,
          autoplay: true,
          slidesToScroll: 1,
        },
      },
    ],
    appendDots: (dots: any) => (
      <Dots>
        <Dot> {dots} </Dot>
      </Dots>
    ),
  };

  return (
    <Section ref={referance}>
      <Container>
        <Title>NOSSOS PARCEIROS</Title>
        <TestimoniesBox>
          <Slider {...settings}>
            {testimonies.map((t, index) => (
              <Slide testimony={t} props={index} key={index} />
            ))}
          </Slider>
        </TestimoniesBox>
      </Container>
    </Section>
  );
}
