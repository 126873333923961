import { styled } from "styled-components";
import { COLORS, FONT_WEIGHT } from "../../../theme";

export const Flex = styled.div`
  display: grid;
  grid-template-columns: 48% 52%;
  padding-top: 135px;

  img {
    width: 100%;
  }

  @media (max-width: 1400px) {
    padding-top: 114px;
  }

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;

    img {
      display: none;
    }
  }

  @media (max-width: 950px) {
    padding-top: 98px;
  }
`;

export const AboutInfo = styled.div`
  width: 100%;
  color: #fff;
  background-color: ${COLORS.SECONDARY};
  display: flex;
  align-items: center;

  div {
    width: 80%;
    margin-left: auto;
    margin-right: 38px;
    display: flex;
    flex-direction: column;

    @media (max-width: 1400px) {
      width: 90%;
      padding: 64px;
    }

    @media (max-width: 1300px) {
      padding: 48px;
    }

    @media (max-width: 1200px) {
      padding: 48px 0;
      margin: 0 auto;
    }

    h1 {
      font-size: 32px;
      font-weight: ${FONT_WEIGHT.SEMI_BOLD};

      @media (max-width: 1400px) {
        font-size: 28px;
      }
    }

    p {
      font-size: 24px;
      font-weight: ${FONT_WEIGHT.REGULAR};
      margin: 38px 0;

      @media (max-width: 1400px) {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }
`;
