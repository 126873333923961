export const COLORS = {
  PRIMARY: "#29425B",
  SECONDARY: "#7ACCE2",
  WHITE: "#FFF",
  GRAY: "#F5F5F5",
};

export const FONT_WEIGHT = {
  THIN: 100,
  LIGHT: 300,
  REGULAR: 400,
  MEDIUM: 500,
  SEMI_BOLD: 600,
  BOLD: 700,
  EXTRA_BOLD: 800,
  BLACK: 900,
};
