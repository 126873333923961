import React, { useState } from "react";
import {
  Box,
  Head,
  Logo,
  MenuMobile,
  OpenMenu,
  Option,
  Options,
} from "./style";
import KeKLogo from "../../assets/images/logo.svg";
import MenuIcon from "../../assets/images/menu.svg";
import MenuClose from "../../assets/images/close.svg";
import { useNavigate } from "react-router-dom";
import ContactLink from "../ContactLink";
import { kekData } from "../../mocks/kekData";

type HeaderProps = {
  notHome?: boolean;
  topRef?: React.MutableRefObject<any>;
  packagesRef?: React.MutableRefObject<any>;
  aboutRef?: React.MutableRefObject<any>;
  testRef?: React.MutableRefObject<any>;
};

export default function Header({
  notHome = false,
  topRef,
  packagesRef,
  aboutRef,
  testRef,
}: HeaderProps) {
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(false);

  function toggleMenu() {
    setOpenMenu(!openMenu);
  }

  function handleMobileMenuClick(func?: React.MutableRefObject<any>) {
    func?.current.scrollIntoView({
      behavior: "smooth",
    });
    setOpenMenu(false);
  }

  return (
    <Head notHome={notHome}>
      <Box>
        <Logo
          src={KeKLogo}
          alt="k&k logo"
          onClick={() => {
            if (!notHome) {
              handleMobileMenuClick(topRef);
            } else {
              navigate("/");
            }
          }}
        />
        <Options>
          <Option
            notHome={notHome}
            onClick={() => {
              handleMobileMenuClick(packagesRef);
            }}
          >
            Nossos pacotes
          </Option>
          <Option
            notHome={notHome}
            onClick={() => {
              handleMobileMenuClick(aboutRef);
            }}
          >
            Quem somos
          </Option>
          <Option
            notHome={notHome}
            onClick={() => {
              handleMobileMenuClick(testRef);
            }}
          >
            Nossos parceiros
          </Option>
          <Option
            notHome={notHome}
            onClick={() => {
              window.open("https://mondialeturismo.com.br/parceiro/", "_blank");
            }}
          >
            Seja nosso parceiro
          </Option>
        </Options>
        <OpenMenu
          notHome={notHome}
          src={!openMenu ? MenuIcon : MenuClose}
          alt="open menu"
          onClick={() => toggleMenu()}
          loading="lazy"
        />
        {!notHome ? (
          <MenuMobile isOpen={openMenu}>
            <button onClick={() => handleMobileMenuClick(packagesRef)}>
              Nosso pacotes
            </button>
            <button onClick={() => handleMobileMenuClick(aboutRef)}>
              Quem somos
            </button>
            <button onClick={() => handleMobileMenuClick(testRef)}>
              Nossos clientes
            </button>
            <button
              onClick={() =>
                window.open(
                  "https://mondialeturismo.com.br/parceiro/",
                  "_blank"
                )
              }
            >
              Seja nosso parceiro
            </button>
          </MenuMobile>
        ) : (
          <ContactLink
            link={kekData.whatsapp}
            title="entre em contato"
            blank
            fontSize={24}
            width="320px"
          />
        )}
      </Box>
    </Head>
  );
}
