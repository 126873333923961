import React from "react";
import { Button } from "./style";

type DownloadButtonProps = {
  onClick: () => void;
  image: string;
  alt: string;
  secondary?: boolean;
  title?: string;
};

export default function DownloadButton({
  onClick,
  image,
  alt,
  secondary,
  title,
}: DownloadButtonProps) {
  return (
    <Button onClick={() => onClick()} secondary={secondary}>
      {title}
      <img src={image} alt={alt} width={18} />
    </Button>
  );
}
