import { styled } from "styled-components";
import { COLORS, FONT_WEIGHT } from "../../../theme";

type CardProps = {
  background: string;
};

export const Section = styled.div`
  .infos {
    padding-top: 64px;
    background-color: ${COLORS.PRIMARY};
  }

  .cards {
    background: linear-gradient(
      180deg,
      ${COLORS.PRIMARY} 25%,
      ${COLORS.WHITE} 25%
    );
  }
`;

export const Title = styled.h1`
  font-size: 32px;
  color: ${COLORS.WHITE};
  font-weight: ${FONT_WEIGHT.BOLD};
  text-align: center;

  @media (max-width: 1400px) {
    font-size: 24px;
  }
`;

export const Description = styled.p`
  font-size: 28px;
  color: ${COLORS.WHITE};
  font-weight: ${FONT_WEIGHT.REGULAR};
  margin-top: 18px;
  text-align: center;

  @media (max-width: 1400px) {
    font-size: 18px;
  }
`;

export const Cards = styled.div`
  display: grid;
  grid-template-columns: 32% 32% 32%;
  gap: 2%;
  padding-top: 48px;

  @media (max-width: 700px) {
    grid-template-columns: 100%;
    gap: 18px;
  }
`;

export const Card = styled.div<CardProps>`
  background-image: ${({ background }) => `url(${background})`};
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  border-radius: 20px;
  height: 480px;
  display: flex;
  flex-direction: column-reverse;
  transition: 0.4s all;

  @media (max-width: 1400px) {
    height: calc(((1200px * 0.32) * 480) / (1400 * 0.32));
  }

  @media (max-width: 1200px) {
    height: calc(((90vw * 0.32) * 480) / (1400 * 0.32));
  }

  @media (max-width: 700px) {
    height: calc((90vw * 480) / (1400 * 0.32));
  }

  &:hover {
    transition: 0.4s all;
    background-size: 110% 110%;
  }

  & p {
    text-align: center;
    color: ${COLORS.WHITE};
    font-size: 24px;
    background-color: ${COLORS.SECONDARY};
    padding: 18px 0;
    border-end-start-radius: 18px;
    border-end-end-radius: 18px;
    font-weight: ${FONT_WEIGHT.BOLD};

    @media (max-width: 1400px) {
      font-size: 22px;
    }

    @media (max-width: 1400px) {
      font-size: 18px;
    }
  }
`;
