import Container from "../Container";
import {
  Flex,
  Info,
  Infos,
  LogoImg,
  LogoMondiale,
  Section,
  Target,
} from "./style";
import Logo from "../../assets/images/logo.svg";
import { kekData } from "../../mocks/kekData";
import Copyright from "../Copyright";
import Mondiale from "../../assets/images/mondiale.svg";
import Insta from "../../assets/images/redes/instagram.svg";

type FooterProps = {
  topRef?: React.MutableRefObject<any>;
};

export default function Footer({ topRef }: FooterProps) {
  return (
    <>
      <Section>
        <Container>
          <Flex>
            <LogoImg
              src={Logo}
              alt="K&K Logo"
              onClick={() => {
                topRef?.current.scrollIntoView({
                  behavior: "smooth",
                });
              }}
            />
            <Infos>
              <Info>
                <b>Atendimento:</b>
                <br />
                Segunda à sexta 10h às 18h
                <br />
                <br />
                <span>
                  <b>Redes sociais:</b>
                  <br />
                  <a
                    href="https://www.instagram.com/kekoperadoradeturismo"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Insta} alt="instagram" width={28} />
                    <span>@kekoperadoradeturismo</span>
                  </a>
                </span>
              </Info>
              <Info>
                <b>Local:</b>
                <br />
                <a href={kekData.address.link} target="_blank" rel="noreferrer">
                  {kekData.address.street}
                  <br />
                  {kekData.address.neighborhood}, nº {kekData.address.number}
                  <br />
                  {kekData.address.room}
                  <br />
                  {kekData.address.city} - {kekData.address.state}
                  <br />
                  CEP: {kekData.address.cep}
                </a>
              </Info>
              <Info>
                <b>Uma representação da:</b>
                <br />
                <a
                  href="https://mondialeturismo.com.br/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <LogoMondiale src={Mondiale} alt="mondiale logo" />
                </a>
              </Info>
            </Infos>
          </Flex>
          <Target>
            Esse site é direcionado exclusivamente para os agentes de viagem.
          </Target>
        </Container>
      </Section>
      <Copyright />
    </>
  );
}
