import React from "react";
import { Tab, Tabs } from "./style";

type TabProps = {
  value: number;
  setValue: (pack: number) => void;
  options: string[];
};

export default function TabsSelection({ value, setValue, options }: TabProps) {
  return (
    <Tabs qtd={options}>
      {options.map((option, index) => (
        <Tab
          active={value === index}
          key={index}
          onClick={() => setValue(index)}
        >
          {option.toUpperCase()}
        </Tab>
      ))}
    </Tabs>
  );
}
