import Spinner from "../Spinner";
import { Content } from "./style";

export default function Loading() {
  return (
    <Content>
      <Spinner wh={48} />
    </Content>
  );
}
