import { TestimoniesProps } from "../../../types/testimonies";
import {
  SlideBox,
  SlideInfo,
  TestemoniesButton,
  TestemoniesInfo,
  TestimoniesName,
} from "./style";
import DefaultImg from "../../../assets/images/testimony/default.png";

type SlideProps = {
  testimony: TestimoniesProps;
  props: any;
};

export default function Slide({ testimony, props }: SlideProps) {
  return (
    <SlideBox>
      <SlideInfo>
        <div>
          <img
            src={
              testimony?.image === "" || !testimony?.image
                ? DefaultImg
                : testimony.image
            }
            alt={testimony.image}
          />
          <TestimoniesName>{testimony.name}</TestimoniesName>
          <TestemoniesInfo>
            <b>Agencia: </b>
            <p>{testimony.agency}</p>
          </TestemoniesInfo>
          <TestemoniesInfo>
            <b>Local: </b>
            <p>{testimony.local}</p>
          </TestemoniesInfo>
          <TestemoniesButton href={`mailto:${testimony.email}`} target="_blank">
            <p>E-mail: {testimony.email}</p>
          </TestemoniesButton>
          <TestemoniesButton
            isWhatsapp
            href={`https://api.whatsapp.com/send?phone=${testimony.whatsapp.replace(
              /[^\d]/g,
              ""
            )}`}
            target="_blank"
          >
            <p>Whatsapp: {testimony.whatsapp}</p>
          </TestemoniesButton>
        </div>
      </SlideInfo>
    </SlideBox>
  );
}
