import styled from "styled-components";
import { COLORS } from "../../theme";

type ButtonProps = {
  secondary?: boolean;
};

export const Button = styled.button<ButtonProps>`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.SECONDARY};
  border-radius: 4px;
  padding: 8px 12px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  font-size: 18px;
  img {
    margin-left: 8px;
  }
`;
