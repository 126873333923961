import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Product from "./pages/Product";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" Component={Home} />
        <Route path="pacote/:id" Component={Product} />
        <Route path="*" Component={Home} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
