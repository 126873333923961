import { styled } from "styled-components";
import { COLORS, FONT_WEIGHT } from "../../../theme";

export const Section = styled.div`
  width: 100%;
  padding: 64px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 950px) {
    flex-direction: column;
  }

  div {
    width: 40%;
    display: flex;
    flex-direction: column;

    @media (max-width: 950px) {
      width: 100%;
    }

    p {
      font-weight: ${FONT_WEIGHT.EXTRA_BOLD};
      font-size: 38px;
      margin-bottom: 48px;
      text-align: center;

      @media (max-width: 1400px) {
        font-size: 28px;
      }
    }
  }

  img {
    width: 40%;

    @media (max-width: 950px) {
      display: none;
    }
  }
`;
