export const kekData = {
  cellphone: "+5527995115485",
  cellphoneFormat: "(27) 99511-5485",
  cellphone2: "+5527998202983",
  cellphoneFormat2: "(27) 99820-2983",
  email: "contato@kekoperadora.com.br",
  whatsapp: "https://api.whatsapp.com/send?phone=5527995115485",
  address: {
    street: "Av. Des. Santos Neves",
    neighborhood: "Praia do Canto",
    number: "601",
    city: "Vitória",
    state: "ES",
    cep: "29055-721",
    room: "Praia Shopping. loja 39",
    link: "https://maps.app.goo.gl/XHtZSuvTPpyYF6mbA",
  },
};

export const sendWhatsAppMessage = (trip: string) => {
  const currentHour = new Date().getHours();
  const greeting =
    currentHour < 12 ? "Bom dia" : currentHour < 18 ? "Boa tarde" : "Boa noite";
  console.log(greeting);
  return `${kekData.whatsapp}&text=${greeting}, gostaria de saber mais do pacote ${trip}`;
};
