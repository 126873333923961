import { useEffect, useState, useCallback } from "react";
import Container from "../../components/Container";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import { packages } from "../../mocks/packages";
import { packageLink } from "../../utils/packageLink";
import { PackagesProps } from "../../types/packages";
import {
  Badge,
  Badges,
  DownloadContent,
  Flex,
  FlexColumns,
  Infos,
  PackageImg,
  PackageInfo,
  Section,
  TabContent,
} from "./style";
import TabsSelection from "../../components/TabsSelection";
import DefaultImg from "../../assets/images/packages/defaultH.png";
import ContactLink from "../../components/ContactLink";
import { SlideType } from "../../mocks/packages";
import { sendWhatsAppMessage } from "../../mocks/kekData";
import DownloadButton from "../../components/DownloadButton";
import ImageDownload from "../../assets/images/image-download.svg";
import DocumentDownload from "../../assets/images/document-download.svg";

export default function Product() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [trip, setTrip] = useState<PackagesProps>();
  const [tabValue, setTabValue] = useState(0);

  const changePackType = useCallback(
    (pack: number) => {
      setTabValue(pack);
    },
    [tabValue]
  );

  const download = async (pdf = false) => {
    if (!trip?.lamina || !trip?.pdf) return;

    const fileResponse = await fetch(pdf ? trip.pdf : trip.lamina);

    const fileBlob = await fileResponse.blob();

    // Create a link element
    const link = document.createElement("a");
    link.href = URL.createObjectURL(fileBlob);
    link.download = `${pdf ? "infos" : "lamina"}-${packageLink(trip.title)}`;
    document.body.appendChild(link);
    link.click();

    // Clean up and remove the link
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  };

  async function getPackage(id: string) {
    const tripFind = packages.find((p) => packageLink(p.title) === id);
    if (!tripFind) {
      navigate("/");
    }
    setTrip(tripFind);
    setLoading(false);
  }

  useEffect(() => {
    if (!id) {
      navigate("/");
    } else {
      getPackage(id);
    }
  }, [id]);

  return loading || !trip ? (
    <Loading />
  ) : (
    <>
      <Header notHome />
      <Container>
        <Section>
          <PackageImg src={trip?.imageH ?? DefaultImg} />
          <PackageInfo>
            <Flex>
              <h1>{trip.title}</h1>
              <Infos>
                <p>
                  A PARTIR DE <b>{trip.qtdParcel}x</b>
                </p>
                <b>
                  {trip.type === SlideType.internacional ? "USD " : "R$ "}
                  <small>
                    {Intl.NumberFormat("pt-br", {
                      minimumFractionDigits: 2,
                    }).format(trip.parcelValue)}
                  </small>
                </b>
                {trip.entry && (
                  <p>
                    + Entrada:{" "}
                    <b>
                      {trip.type === SlideType.internacional ? "USD " : "R$ "}
                      {Intl.NumberFormat("pt-br", {
                        minimumFractionDigits: 2,
                      }).format(trip.entry)}
                    </b>
                  </p>
                )}
                <p>
                  Total à vista por:{" "}
                  <b>
                    {trip.type === SlideType.internacional ? "USD " : "R$ "}
                    {Intl.NumberFormat("pt-br", {
                      minimumFractionDigits: 2,
                    }).format(trip.value)}
                  </b>
                </p>
              </Infos>
              <FlexColumns>
                <span>{`${trip.type < 2 ? "Pacote" : ""} ${
                  SlideType[trip.type]
                }`}</span>
                <span>{trip.period}</span>
                <ContactLink
                  title="reservar"
                  link={sendWhatsAppMessage(trip.title)}
                  blank
                />
              </FlexColumns>
            </Flex>
          </PackageInfo>
          <Badges>
            <Badge>{`${trip.initialDate} à ${trip.finalDate}`}</Badge>
          </Badges>
          <TabsSelection
            value={tabValue}
            setValue={changePackType}
            options={
              trip?.dayByDay?.length
                ? ["inclui", "descrição", "day by day"]
                : ["inclui", "descrição"]
            }
          />
          <TabContent>
            {tabValue === 0 && (
              <p>
                <ul>
                  {trip.include.map((inc, index) => (
                    <li key={index}>{inc}</li>
                  ))}
                </ul>
              </p>
            )}
            {tabValue === 1 && (
              <p>
                {trip.description.map((desc) => (
                  <>
                    {desc}
                    <br />
                  </>
                ))}
                <br />
                <br />
                {trip.policy.map((p) => (
                  <i>{p}</i>
                ))}
                <DownloadContent>
                  {trip?.lamina && (
                    <DownloadButton
                      onClick={() => download(false)}
                      image={ImageDownload}
                      alt="download image"
                      secondary
                      key={"image"}
                      title="Baixar lâmina"
                    />
                  )}
                  {trip?.pdf && (
                    <DownloadButton
                      onClick={() => download(true)}
                      image={DocumentDownload}
                      alt="download pdf"
                      secondary
                      key={"pdf"}
                      title="Baixar roteiro"
                    />
                  )}
                </DownloadContent>
              </p>
            )}
            {tabValue === 2 &&
              trip.dayByDay.map((day) => (
                <p>
                  <b>{day.day}</b>
                  {day.description.length <= 1 ? (
                    <span>{day.description.toString()}</span>
                  ) : (
                    <ul>
                      {day.description.map((desc) => (
                        <li>{desc}</li>
                      ))}
                    </ul>
                  )}
                </p>
              ))}
          </TabContent>
        </Section>
      </Container>
      <Footer />
    </>
  );
}
