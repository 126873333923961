import { styled } from "styled-components";
import { COLORS, FONT_WEIGHT } from "../../../theme";
import ArrowRight from "../../../assets/images/arrow-right.svg";
import ArrowLeft from "../../../assets/images/arrow-left.svg";

type TestemoniesButtonProps = {
  isWhatsapp?: boolean;
};

export const Section = styled.div`
  width: 100%;
  padding: 135px 0;
  background-color: ${COLORS.GRAY};

  @media (max-width: 1400px) {
    padding: 114px 0;
  }

  @media (max-width: 950px) {
    padding: 98px 0;
  }

  div.slick-slider {
    button.slick-arrow {
      background-color: ${COLORS.PRIMARY};
      background-image: url(${ArrowRight});
      background-size: 22px;
      background-repeat: no-repeat;
      background-position: center;
      width: 40px;
      height: 40px;
      border-radius: 10px;
      right: -40px;

      @media (max-width: 1280px) {
        right: -24px;
      }

      &::before {
        opacity: 1;
        content: "";
      }
    }

    button.slick-arrow.slick-prev {
      background-image: url(${ArrowLeft});
      z-index: 9;
      left: -40px;

      @media (max-width: 1280px) {
        left: -24px;
      }
    }
  }
`;

export const Title = styled.h1`
  font-size: 32px;
  text-align: center;
  font-weight: ${FONT_WEIGHT.EXTRA_BOLD};
  color: ${COLORS.PRIMARY};

  @media (max-width: 1400px) {
    font-size: 28px;
  }
`;

export const TestimoniesBox = styled.div`
  width: 100%;
  margin-top: 48px;
`;

// SLIDER

export const Dots = styled.div`
  border-radius: 12px;
  padding: 12px 0;
`;

export const Dot = styled.ul`
  margin-bottom: -32px;

  li button:before {
    font-size: 12px;
    opacity: 1;
    color: #d7d7d7;
  }

  li.slick-active button:before {
    color: ${COLORS.PRIMARY};
    opacity: 1;
  }
`;

export const SlideBox = styled.div`
  height: 254px;
  margin-top: 70px;

  @media (max-width: 1400px) {
    margin-top: 84px;
  }
`;

export const SlideInfo = styled.div`
  width: 94%;
  margin: 0 auto;
  background-color: ${COLORS.WHITE};
  box-shadow: 6px 6px 4px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px;

  div {
    padding: 12px;

    img {
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
      margin-left: auto;
      position: relative;
      margin-top: -80px;
      z-index: 1;
      border-radius: 50%;
      width: 120px;

      @media (max-width: 1400px) {
        width: 100px;
        margin-top: -60px;
      }
    }
  }
`;

export const TestimoniesName = styled.b`
  font-weight: ${FONT_WEIGHT.EXTRA_BOLD};
  color: ${COLORS.PRIMARY};
  font-size: 24px;

  @media (max-width: 1400px) {
    font-size: 22px;
  }

  @media (max-width: 1200px) {
    font-size: 20px;
  }
`;

export const TestemoniesInfo = styled.p`
  padding: 6px 12px;
  margin: 2px 0;
  display: flex;
  color: #000;
  text-decoration: none;

  b {
    font-weight: ${FONT_WEIGHT.BOLD};
    font-size: 18px;
  }

  p {
    width: 75%;
    font-weight: ${FONT_WEIGHT.REGULAR};
    font-size: 16px;
    margin-left: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const TestemoniesButton = styled.a<TestemoniesButtonProps>`
  text-decoration: none;

  p {
    width: calc(100% - 24px);
    padding: 8px 12px;
    margin: 4px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #fff;
    background-color: ${({ isWhatsapp }) =>
      isWhatsapp ? "#25d366" : COLORS.SECONDARY};
    border-radius: 10px;
  }
`;
