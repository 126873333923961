import Container from "../../../components/Container";
import { Card, Cards, Description, Section, Title } from "./style";
import Internacionais from "../../../assets/images/card/internacionais.png";
import Cruzeiro from "../../../assets/images/card/cruzeiro.png";
import Aviao from "../../../assets/images/card/aviao.png";

export default function Intro() {
  return (
    <Section>
      <div className="infos">
        <Container>
          <Title>AS MELHORES EXPERIÊNCIAS</Title>
          <Description>
            Com enorme bagagem e excelência no atendimento há mais de 20 anos, a
            K&K foi criada para manter o padrão de qualidade e de atendimento
            que nossos clientes necessitam, com flexibilização e sempre os
            melhores roteiros para sua viagem
          </Description>
        </Container>
      </div>
      <div className="cards">
        <Container>
          <Cards>
            <Card background={Internacionais}>
              <p>VIAGENS INTERNACIONAIS</p>
            </Card>
            <Card background={Cruzeiro}>
              <p>VIAGENS DE CRUZEIRO</p>
            </Card>
            <Card background={Aviao}>
              <p>VIAGENS DE AVIÃO</p>
            </Card>
          </Cards>
        </Container>
      </div>
    </Section>
  );
}
